import {
  AccessCollectionRequestIcon,
  AccessCurrencyRequestIcon,
  FillNFTOrderIcon,
  SendAssetIcon,
} from '@moonpay-widget/ui-kit';
import {
  ShareIcon,
  ShoppingBagIcon,
} from '@moonpay-widget/ui-kit/src/PromptIcons';
import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
  WalletNetwork,
  WalletNetworkToSymbolMap,
} from '@moonpay/login-common';
import { BigNumber, utils } from 'ethers';
import {
  createBtcTransaction,
  fetchEstimatedSmartFeeByMode,
  getBtcUtxos,
  selectUtxos,
  sendRawBtcTransaction,
} from 'src/messages/walletProxy/methods/sendTransaction/bitcoin/utils';
import Erc20Send from 'src/messages/walletProxy/methods/sendTransaction/components/Erc20Send';
import GenericNftSendTransaction from 'src/messages/walletProxy/methods/sendTransaction/components/GenericNftSendTransaction';
import NftApproval from 'src/messages/walletProxy/methods/sendTransaction/components/NftApproval';
import {
  Erc20TransactionType,
  getErc20Action,
} from 'src/messages/walletProxy/methods/sendTransaction/erc20';
import {
  HmErc721TransactionType,
  getHmErc721Action,
} from 'src/messages/walletProxy/methods/sendTransaction/hmErc721';
import {
  isSafeTransferFrom,
  isTargetNFT,
} from 'src/messages/walletProxy/methods/sendTransaction/sendNFT';
import {
  formatErc20Value,
  formatFee,
  formatValue,
  formatValueEth,
} from 'src/messages/walletProxy/methods/sendTransaction/utils';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import {
  isBTCAddressValid,
  isSegWitAddress,
} from 'src/messages/walletProxy/utils/validateBitcoinAddress';
import { ErrorManager } from 'src/utils/errorManager';
import { EventTrackingUtils } from 'src/utils/eventTracking';
import {
  KmsWalletTransactionType,
  createKmsWalletTransaction,
} from 'src/wallet/walletProvider/kms/kmsApi';
import { UserWalletAnalyticsEvent } from '../../../../types/UserWalletAnalyticsEvent';
import { Logger } from '../../../../utils/logger';
import GenericSendTransaction from './components/GenericSendTransaction';
import NftPurchase from './components/NftPurchase';
import { getNftApproval } from './nftApproval';
import { decodeSeaportTx, isOpenseaContractAddress } from './seaport/utils';

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);

export async function sendTransactionValidate({
  request,
  network,
  walletStorage,
}: PromptRequest) {
  const networkSymbol = WalletNetworkToSymbolMap[network];

  if (request.method !== `${networkSymbol}_sendTransaction`) {
    throw errorManager.getServerError(
      'sendTransactionValidate',
      `Invalid request method`,
    );
  }
  if (!request.params || !Array.isArray(request.params)) {
    throw errorManager.getServerError(
      'sendTransactionValidate',
      `Invalid request params`,
    );
  }
  if (request.params.length !== 1) {
    throw errorManager.getServerError(
      'sendTransactionValidate',
      `Invalid request params length`,
    );
  }
  if (typeof request.params[0] !== 'object') {
    throw errorManager.getServerError(
      'sendTransactionValidate',
      `Invalid request params[0] type, must be an object`,
    );
  }

  // check that param[0] has all required fields
  let requiredFields: string[] = [];
  let missingFields: string[] = [];

  // ensure that from and to are valid addresses
  const { from, to } = request.params![0];
  switch (network) {
    case WalletNetwork.Ethereum:
      requiredFields = ['from', 'to'];

      missingFields = requiredFields.filter(
        (field) => !request.params![0][field],
      );

      if (missingFields.length > 0) {
        throw new EthProviderRpcError(
          EthProviderRpcErrorCode.IncorrectArgument,
          `Invalid request params[0] missing fields: ${missingFields.join(
            ', ',
          )}`,
        );
      }

      if (!utils.isAddress(from)) {
        throw new EthProviderRpcError(
          EthProviderRpcErrorCode.IncorrectArgument,
          `Invalid from ETH address: ${from}`,
        );
      }
      if (!utils.isAddress(to)) {
        throw new EthProviderRpcError(
          EthProviderRpcErrorCode.IncorrectArgument,
          `Invalid to ETH address: ${to}`,
        );
      }

      break;
    case WalletNetwork.Bitcoin:
      requiredFields = ['to', 'value'];

      missingFields = requiredFields.filter((field) => {
        const fieldValue = request.params![0][field];
        return fieldValue === undefined || fieldValue === null;
      });

      if (missingFields.length > 0) {
        throw errorManager.getServerError(
          'sendTransactionValidate',
          `Invalid request params[0] missing fields: ${missingFields.join(
            ', ',
          )}`,
        );
      }

      const optionalFeeField = request.params![0]['fee'] as string;
      if (optionalFeeField) {
        if (isNaN(parseFloat(optionalFeeField))) {
          throw errorManager.getServerError(
            'sendTransactionValidate',
            `Invalid fee field type, this field must be a number`,
          );
        }

        if (parseFloat(optionalFeeField) <= 0) {
          throw errorManager.getServerError(
            'sendTransactionValidate',
            `Invalid fee field value, this field must be greater than 0`,
          );
        }
      }

      const activeChainId =
        walletStorage.activeChainId.getActiveChainIdByNetwork(network);

      if (!isBTCAddressValid(to, activeChainId)) {
        throw new EthProviderRpcError(
          EthProviderRpcErrorCode.IncorrectArgument,
          `Invalid to BTC address: ${to}`,
        );
      }

      // check value is a number
      if (typeof request.params![0].value !== 'number') {
        throw new EthProviderRpcError(
          EthProviderRpcErrorCode.IncorrectArgument,
          `Invalid value: ${request.params![0].value}`,
        );
      }
      break;
    default:
      throw errorManager.getServerError(
        'sendTransactionValidate',
        `Invalid network`,
      );
  }
}

export async function sendTransactionExecute({
  request,
  wallet,
  network,
  abstractWallet,
  walletStorage,
  origin,
}: PromptRequest) {
  const unsignedTransaction = request.params![0];
  // remove `from` and `gas` from unsigned transaction as they will
  // be populated by `walletObj.populateTransaction` via the signer
  // and the `gas` will be calculated incorrectly. If these fields
  // are not removed an error will be thrown by populateTransaction.

  try {
    switch (network) {
      case WalletNetwork.Ethereum:
        const { from, gas, ...unsignedTransactionWithoutFrom } =
          unsignedTransaction;
        let populatedTrx = unsignedTransactionWithoutFrom;
        try {
          unsignedTransactionWithoutFrom.gasLimit = gas;
          populatedTrx = await wallet.populateTransaction(
            unsignedTransactionWithoutFrom,
          );
        } catch (err: any) {
          logger.logError(
            'sendTransactionExecute',
            'Failed to populate transaction',
            { err: err?.toString(), unsignedTransaction },
          );
          throw errorManager.getServerError(
            'sendTransactionExecute',
            `Failed to populate transaction`,
          );
        }
        const rawTransaction = await wallet.signTransaction(populatedTrx);
        const transaction = await wallet.provider.sendTransaction(
          rawTransaction,
        );

        const transactionHash = transaction.hash;
        EventTrackingUtils.trackEvent(
          UserWalletAnalyticsEvent.approveTransactionCompleted,
          abstractWallet.address,
          network,
          { transactionHash },
        );

        try {
          await createKmsWalletTransaction({
            transactionType: KmsWalletTransactionType.crypto,
            transactionMetadata: {
              baseCurrency: {
                code: 'eth',
                type: 'crypto',
                precision: 18,
              },
              baseCurrencyAmount: transaction.value.toString(),
            },
            networkCode: WalletNetwork.Ethereum,
            transactionHash,
            from: transaction.from,
            to: transaction.to ?? null,
            transactionFee: transaction.gasPrice?.toString() || '',
            transactionFeeUnits: 'gwei',
          });
        } catch (err: any) {
          logger.logError(
            'sendTransactionExecute',
            'Failed to create kms wallet transaction',
            {
              err: err?.toString(),
              transactionHash,
              from,
              network: WalletNetwork.Ethereum,
            },
          );
        }
        return transactionHash;
      case WalletNetwork.Bitcoin:
        const { to, value, fee } = unsignedTransaction;

        const amount = Number(value.toString());

        const activeChainId =
          walletStorage.activeChainId.getActiveChainIdByNetwork(network);
        const isSegWit = isSegWitAddress(to, activeChainId);
        const { utxos } = await getBtcUtxos(
          abstractWallet.address,
          activeChainId,
        );

        let feeInSats = parseFloat(fee);
        // Fallback to auto fee calculation if fee is not provided
        if (isNaN(feeInSats)) {
          const chainId =
            walletStorage.activeChainId.getActiveChainIdByNetwork(network);
          const smartFeesByMode = await fetchEstimatedSmartFeeByMode(
            abstractWallet.address,
            value,
            chainId,
          );
          feeInSats = parseFloat(smartFeesByMode.medium.nativeCryptoFee);
        }

        const { inputs, change } = await selectUtxos(
          utxos,
          amount,
          activeChainId,
          isSegWit,
          feeInSats,
        );

        const tx = await createBtcTransaction(
          inputs,
          change,
          to,
          amount,
          abstractWallet,
          activeChainId,
        );

        logger.logDebug('sendTransactionExecute', 'Sending BTC transaction', {
          transaction: tx,
          origin: origin,
          abstractWalletAddress: abstractWallet.address,
          walletAddress: wallet.address,
          network: WalletNetwork.Bitcoin,
        });
        const txHash = await sendRawBtcTransaction(tx, activeChainId);
        EventTrackingUtils.trackEvent(
          UserWalletAnalyticsEvent.approveTransactionCompleted,
          abstractWallet.address,
          network,
          { transactionHash: txHash },
        );

        try {
          logger.logDebug(
            'sendTransactionExecute',
            'Saving Kms Wallet Transaction',
            {
              transactionHash: txHash,
              origin: origin,
              abstractWalletAddress: abstractWallet.address,
              walletAddress: wallet.address,
              network: WalletNetwork.Bitcoin,
            },
          );
          await createKmsWalletTransaction({
            transactionType: KmsWalletTransactionType.crypto,
            transactionMetadata: {
              baseCurrency: {
                code: 'btc',
                type: 'crypto',
                precision: 8,
              },
              baseCurrencyAmount: amount.toString(), // @dev in sats
            },
            networkCode: WalletNetwork.Bitcoin,
            transactionHash: txHash,
            from: abstractWallet.address,
            to: to ?? null,
            transactionFee: feeInSats.toString(),
            transactionFeeUnits: 'satoshis',
          });
        } catch (err: any) {
          logger.logError(
            'sendTransactionExecute',
            'Failed to create kms wallet transaction',
            {
              err: err?.toString(),
              transactionHash: txHash,
              from: abstractWallet.address,
              network: WalletNetwork.Bitcoin,
            },
          );
        }
        return txHash;
      default:
        throw errorManager.getServerError(
          'sendTransactionExecute',
          `Invalid network`,
        );
    }
  } catch (err: any) {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      err.message,
    );
  }
}

export async function sendTransactionReject(req: PromptRequest) {
  EventTrackingUtils.trackEvent(
    UserWalletAnalyticsEvent.approveTransactionCancelled,
    req.abstractWallet.address,
    req.network,
  );
}

export async function sendTransactionPrompt({
  request,
  wallet,
  walletStorage,
  network,
  abstractWallet,
}: PromptRequest) {
  const chainId =
    walletStorage.activeChainId.getActiveChainIdByNetwork(network);
  const chain = walletStorage.chains.getChain(chainId.toString(), network);
  if (!chain) {
    throw errorManager.getServerError(
      'sendTransactionPrompt',
      `Invalid chainId`,
    );
  }
  const currencyCode = chain.nativeCurrency.symbol;
  let transactionLogo = SendAssetIcon;

  const transaction = request.params![0];
  const { value, data, to, from, gas, maxFeePerGas, maxPriorityFeePerGas } =
    transaction;
  let networkFee: string;

  switch (network) {
    case WalletNetwork.Ethereum:
      let gasLimit: BigNumber;
      try {
        if (gas) {
          gasLimit = BigNumber.from(gas.toString());
        } else {
          gasLimit = await wallet.provider.estimateGas(transaction);
        }
      } catch (error) {
        gasLimit = BigNumber.from(100_000); // if estimateGas fails, we fall back to this value
      }

      const feeData = await wallet.provider.getFeeData();
      const gasPrice = maxFeePerGas
        ? BigNumber.from(maxFeePerGas.toString())
        : feeData.maxFeePerGas ?? feeData.gasPrice!; // if non EIP-1159, fall back to gasPrice
      networkFee = formatFee(
        gasLimit,
        gasPrice,
        maxPriorityFeePerGas
          ? BigNumber.from(maxPriorityFeePerGas.toString())
          : undefined,
      );

      break;
    case WalletNetwork.Bitcoin:
      const customFee = request.params![0]['fee'];
      if (customFee) {
        networkFee = (parseFloat(customFee) / 100_000_000).toString();
      } else {
        // TODO: Break this out into all modes and pass it into the prompt for users to select
        const smartFeesByMode = await fetchEstimatedSmartFeeByMode(
          abstractWallet.address,
          value,
          chainId,
        );

        const calculatedFee = smartFeesByMode.medium.nativeCryptoFee;
        request.params![0].fee = calculatedFee; // TODO: We shouldn't be passing info via the params like this, need to re-architect - could be dangerous
        networkFee = (parseFloat(calculatedFee) / 100_000_000).toString();
      }
      break;
    default:
      throw errorManager.getServerError(
        'sendTransactionPrompt',
        `Invalid network`,
      );
  }

  const erc721Action = await getHmErc721Action(wallet, transaction);
  if (erc721Action) {
    if (erc721Action.type === HmErc721TransactionType.BUY) {
      transactionLogo = FillNFTOrderIcon;
      const nftName = 'HyperMint NFT';

      // note: price is the native currency of the chain
      // and its _amount * pricePerToken
      const price = formatValueEth(value);

      const props = {
        nfts: [
          {
            name: nftName,
            price: Number.parseFloat(price),
          },
        ],
        networkFee,
        currencyCode,
      };

      return {
        title: 'Buy HyperMint NFT',
        partnerLogo: ShoppingBagIcon,
        component: <NftPurchase {...props} />,
        aboveButtonsText:
          'Only confirm if you fully understand and trust the requesting site',
      };
    }
  }

  const erc20Action = await getErc20Action(wallet, transaction);
  if (erc20Action) {
    if (erc20Action.type === Erc20TransactionType.TRANSFER) {
      const props = {
        currencyCode: erc20Action.token.symbol,
        networkFee,
        to: erc20Action.to,
        value: formatErc20Value(erc20Action.token, erc20Action.value),
        token: erc20Action.token,
        nativeCurrency: chain.nativeCurrency.symbol,
      };

      return {
        title: 'Send Tokens',
        component: <Erc20Send {...props} />,
        partnerLogo: transactionLogo,
        aboveButtonsText:
          'Please review the above before confirming as transactions cannot be reversed.',
        approveButtonText: 'Confirm and send',
      };
    } else if (erc20Action.type === Erc20TransactionType.APPROVAL) {
      transactionLogo = AccessCurrencyRequestIcon;
      const props = {
        currencyCode: erc20Action.token.symbol,
        networkFee,
        to: erc20Action.spender,
        spender: erc20Action.spender,
        value: formatErc20Value(erc20Action.token, erc20Action.value),
        all:
          erc20Action.value.toHexString() ===
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
        token: erc20Action.token,
        nativeCurrency: chain.nativeCurrency.symbol,
      };

      return {
        title: `Allow access to your ${erc20Action.token.name}`,
        message:
          'By granting permission, you are allowing this contract to access and transfer your funds.',
        approveButtonText: 'Allow access',
        component: <Erc20Send {...props} />,
        aboveButtonsText:
          'Only if you fully understand and trust the requesting site',
      };
    }
  }

  // TODO: extend this for other nft contract interactions
  const isNftPurchase = isOpenseaContractAddress(to);
  // TODO: need to figure out how it is a sell or  purchase
  if (isNftPurchase) {
    transactionLogo = FillNFTOrderIcon;
    // TODO: need to figure out how it is a sell or  purchase
    const decodedTx = decodeSeaportTx(data, to);
    if (decodedTx) {
      const { nftName, price } = decodedTx;
      const props = {
        nfts: [
          {
            name: nftName,
            price: Number.parseFloat(price),
          },
        ],
        networkFee,
        currencyCode,
      };
      return {
        title: 'Purchase NFT',
        partnerLogo: ShoppingBagIcon,
        component: <NftPurchase {...props} />,
        aboveButtonsText:
          'Only confirm if you fully understand and trust the requesting site',
      };
    }
  }

  const isNFT = await isTargetNFT(wallet, transaction);
  if (isNFT) {
    const nftApproval = await getNftApproval(wallet, transaction);
    if (nftApproval) {
      transactionLogo = AccessCollectionRequestIcon;
      const props = {
        currencyCode,
        networkFee,
        contractAddress: nftApproval.spender,
        nftName: nftApproval.name,
        nftContractAddress: transaction.to!,
        to: nftApproval.spender,
        tokenId: nftApproval.tokenId,
      };

      return {
        title: `Allow access to your ${nftApproval.name}`,
        message: `Allow access to your ${nftApproval.name} collection?`,
        component: <NftApproval {...props} />,
        aboveButtonsText:
          'Only if you fully understand and trust the requesting site',
      };
    }

    const nftTransfer = await isSafeTransferFrom(wallet, transaction);
    if (nftTransfer) {
      const props = {
        networkFee,
        currencyCode,
        to: nftTransfer.to,
        from: nftTransfer.from,
        nfts: nftTransfer.nfts,
      };

      return {
        title: 'Send NFT',
        partnerLogo: ShareIcon,
        message:
          'By approving this transaction, you are sending the following NFTs to the wallet address below.',
        component: <GenericNftSendTransaction {...props} />,
        aboveButtonsText:
          'Please review the above before confirming as transactions cannot be reversed.',
      };
    }
  }
  // if all else fails above, show generic send transaction
  const props = {
    currencyCode,
    value: formatValue(value || BigNumber.from(0), network),
    networkFee,
    from,
    to,
    data,
  };
  return {
    title: `Send ${currencyCode}`,
    partnerLogo: transactionLogo,
    component: <GenericSendTransaction {...props} />,
    aboveButtonsText:
      'Please review the above before confirming as transactions cannot be reversed.',
    approveButtonText: 'Confirm and send',
  };
}
