import { WalletNetwork } from "@moonpay/login-common";
import { BaseStorage } from "src/wallet/storage/BaseStorage";
import { DEFAULT_ACTIVE_CHAINS } from "src/wallet/storage/defaultActiveChain";

export type ActiveChainsData = {
    [network in WalletNetwork]: number;
};

const CHAIN_ID_KEY = 'chain-id';

export class ActiveChainsStorage extends BaseStorage<ActiveChainsData> {
    constructor() {
        super({ key: CHAIN_ID_KEY, defaultValue: DEFAULT_ACTIVE_CHAINS });
    }

    getActiveChains() : ActiveChainsData {
        return this.value;
    }

    getActiveChainIdByNetwork(network: WalletNetwork) : number {
        return this.value[network];
    }

    setActiveChainIdByNetwork(network: WalletNetwork, chainId: number) {
        this.update((activeChains) => {
            return {
                ...activeChains,
                [network]: chainId,
            };
        });
    }

    reset() {
        if (!this.value[WalletNetwork.Ethereum]) {
            this.update(() => DEFAULT_ACTIVE_CHAINS);
        }
    }
}
