import {
  sendTransactionExecute,
  sendTransactionPrompt,
  sendTransactionReject,
  sendTransactionValidate,
} from 'src/messages/walletProxy/methods/sendTransaction/sendTransaction';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const sendTransaction: MethodImplementation = {
  validate: sendTransactionValidate,
  prompt: sendTransactionPrompt,
  execute: sendTransactionExecute,
  reject: sendTransactionReject,
};

export { sendTransaction };
