import ecc from '@bitcoinerlab/secp256k1';
import { WalletNetwork } from '@moonpay/login-common';
import axios from 'axios';
import BIP32Factory, { BIP32Interface } from 'bip32';
import * as bip39 from 'bip39';
import * as bitcoin from 'bitcoinjs-lib';
import { ECPairFactory, ECPairInterface } from 'ecpair';
import { ErrorManager } from 'src/utils/errorManager';
import { Logger } from 'src/utils/logger';
import { AbstractWallet } from 'src/wallet/types/Wallet';

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);
const cryptoApiBaseUrl = process.env.REACT_APP_CRYPTO_API_URL || '';

export interface Utxo {
  txid: string;
  vout: number;
  value: number;
  height: number;
  confirmations: number;
  hex: string;
}

export type FeePriceDetails = {
  nativeCryptoFee: string;
  nativeCryptoPriorityFee?: string; // Only applies to EVM
  nativeCryptoUnits: string;
  fiatAmount: string;
  fiatCode: string;
  estimateInMs: number;
  gasLimit?: string; // Only applies to EVM
};

export type FeePriceResponse = {
  slow: FeePriceDetails;
  medium: FeePriceDetails;
  fast: FeePriceDetails;
};

export interface UtxoResponse {
  utxos: Utxo[];
}

export enum FeeEstimatorMode {
  FAST = 'fast',
  MEDIUM = 'medium',
  SLOW = 'slow',
}

function getSigner(chainId: number, wallet: AbstractWallet): ECPairInterface {
  const seed = bip39.mnemonicToSeedSync(wallet.mnemonic.phrase);
  const btcWallet = deriveWallets(wallet.address, seed);

  if (!btcWallet?.privateKey) {
    throw errorManager.getServerError('getSigner', `Private key not found`);
  }

  const ECPair = ECPairFactory(ecc);
  return ECPair.fromPrivateKey(btcWallet.privateKey, {
    network:
      chainId === 0 ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
  });
}

export function deriveWallets(
  addressToMatch: string,
  seed: Buffer,
): BIP32Interface {
  const isMainnet = addressToMatch.startsWith('bc');
  const chainId = isMainnet ? 0 : 1;
  const network = isMainnet
    ? bitcoin.networks.bitcoin
    : bitcoin.networks.testnet;

  const bip32 = BIP32Factory(ecc);
  // recover from master extended key
  const btcWalletFromExtendedKey = bip32.fromSeed(seed);
  const addressFromExtendedKey = bitcoin.payments.p2wpkh({
    pubkey: btcWalletFromExtendedKey.publicKey,
    network,
  }).address!;

  // recover from child key derived via BIP84
  const btcWalletFromChildKey = bip32
    .fromSeed(seed)
    .derivePath("m/84'/0'/0'/0/0");
  const addressFromChildKey = bitcoin.payments.p2wpkh({
    pubkey: btcWalletFromChildKey.publicKey,
    network,
  }).address!;

  let btcWallet: BIP32Interface;
  if (addressToMatch === addressFromExtendedKey) {
    btcWallet = btcWalletFromExtendedKey;
  } else if (addressToMatch === addressFromChildKey) {
    btcWallet = btcWalletFromChildKey;
  } else {
    throw errorManager.getServerError(
      'getSigner',
      `Chain id: ${chainId}
      Failed to identify wallet address format for ${addressToMatch}
      extendedKey: ${addressFromExtendedKey}, childKey: ${addressFromChildKey}`,
    );
  }

  return btcWallet;
}

async function sendRawBtcTransaction(
  rawTransaction: string,
  chainId: number,
): Promise<string> {
  try {
    const url = new URL(`${cryptoApiBaseUrl}/api/v1/transfer`);

    const response = await axios.post<string>(url.toString(), {
      chain: WalletNetwork.Bitcoin,
      network: chainId === 0 ? 'mainnet' : 'testnet',
      transactionData: rawTransaction,
    });

    logger.logInfo(
      'sendRawBtcTransaction',
      `Succeded in sending raw BTC transaction: ${response.data}`
    )

    return response.data;

  } catch (error: any) {
    console.debug(error, error.message);
    throw errorManager.getServerError(
      'sendRawBtcTransaction',
      `Failed to send raw BTC transaction: ${error} -> ${error?.message}`,
    );
  }
}

async function craftBtcTransactionIO(
  inputs: Utxo[],
  change: number,
  toAddress: string,
  amount: number,
  abstractWallet: AbstractWallet,
  activeChainId: number,
): Promise<bitcoin.Psbt> {
  const outputs = [
    { address: toAddress, value: amount },
    { address: abstractWallet.address, value: change },
  ];

  const psbt = new bitcoin.Psbt({
    network:
      activeChainId === 0 ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
  });

  inputs.forEach((input) => {
    psbt.addInput({
      hash: input.txid,
      index: input.vout,
      nonWitnessUtxo: Buffer.from(input.hex, 'hex'),
    });
  });

  outputs.forEach((output) => {
    psbt.addOutput({
      address: output.address,
      value: output.value,
    });
  });

  return psbt;
}

async function createBtcTransaction(
  inputs: Utxo[],
  change: number,
  toAddress: string,
  amount: number,
  abstractWallet: AbstractWallet,
  activeChainId: number,
) {
  const partiallySignedBitcoinTransactionInternal = await craftBtcTransactionIO(
    inputs,
    change,
    toAddress,
    amount,
    abstractWallet,
    activeChainId,
  );
  const wallet = getSigner(activeChainId, abstractWallet);
  partiallySignedBitcoinTransactionInternal.signAllInputs(wallet);
  partiallySignedBitcoinTransactionInternal.finalizeAllInputs();
  return partiallySignedBitcoinTransactionInternal.extractTransaction().toHex();
}

async function getBtcUtxos(
  address: string,
  chainId: number,
): Promise<UtxoResponse> {
  const url = new URL(`${cryptoApiBaseUrl}/api/v1/utxos`);
  url.searchParams.append('address', address);
  url.searchParams.append('chain', WalletNetwork.Bitcoin);
  url.searchParams.append('network', chainId === 0 ? 'mainnet' : 'testnet');

  try {
    const response = await axios.get<UtxoResponse>(url.toString());
    return response.data;
  } catch (error) {
    // throw here as no transactions can be made without UTXOs
    throw errorManager.getServerError(
      'getBtcUtxos',
      `Failed to fetch UTXOs for address ${address} on ${chainId === 0 ? 'mainnet' : 'testnet'
      }`,
    );
  }
}

async function selectUtxos(
  utxos: Utxo[],
  amount: number,
  chainId: number,
  isSegWit: boolean,
  networkFeeInSats: number,
): Promise<{ inputs: Utxo[]; change: number; networkFeeInSatoshis: number }> {
  logger.logInfo('selectUtxos', `Selecting UTXOs for amount ${amount}`, {
    utxos,
    amount,
    chainId,
    isSegWit,
  });

  let inputSum = 0;
  let selectedUtxos = [];

  for (const utxo of utxos) {
    if (inputSum >= amount + networkFeeInSats + 1) {
      // Ensure there's always at least 1 satoshi for change.
      break;
    }

    selectedUtxos.push(utxo);
    inputSum += Number(utxo.value);
  }

  logger.logInfo('selectUtxos', `Calculated Input Sum: ${inputSum}`, {
    selectedUtxos,
  });

  if (inputSum < amount + networkFeeInSats + 1) {
    throw errorManager.getClientError(
      'selectUtxos',
      `Insufficient funds for the transaction`,
    );
  }

  return {
    inputs: selectedUtxos,
    change: inputSum - amount - networkFeeInSats,
    networkFeeInSatoshis: networkFeeInSats,
  };
}

async function fetchEstimatedSmartFeeByMode(
  from: string,
  amount: number,
  chainId: number,
) {
  const amountInBtc = amount / 100_000_000;
  const url = new URL(`${cryptoApiBaseUrl}/api/v1/fee-price`);
  url.searchParams.append('chain', WalletNetwork.Bitcoin);
  url.searchParams.append('network', chainId === 0 ? 'mainnet' : 'testnet');
  url.searchParams.append('from', from);
  url.searchParams.append('value', amountInBtc.toString());

  try {
    const response = await axios.get<FeePriceResponse>(url.toString());
    return response.data;
  } catch (e) {
    throw errorManager.getServerError(
      'estimateSmartFee',
      `Failed to get fee rate: ${e}`,
    );
  }
}

// returned value is in satoshis
function estimateTransactionFee(
  utxos: number,
  feeRate: number,
  isSegWit: boolean,
): number {
  const bytesPerInput = isSegWit ? 69 : 148; // Size of an input in bytes
  const bytesPerOutput = isSegWit ? 31 : 34; // Size of an output in bytes
  const bytesPerHeader = 10; // Size of the transaction header in bytes

  const numInputs = utxos;
  const numOutputs = 2; // Assuming one output for the transaction and one for the change

  // Calculate the size of the transaction in bytes
  const transactionSize =
    numInputs * bytesPerInput + numOutputs * bytesPerOutput + bytesPerHeader;

  // Calculate the estimated fee in satoshis
  const estimatedFee = transactionSize * feeRate;

  return estimatedFee;
}

export {
  craftBtcTransactionIO,
  createBtcTransaction,
  estimateTransactionFee,
  fetchEstimatedSmartFeeByMode,
  getBtcUtxos,
  selectUtxos,
  sendRawBtcTransaction
};

