/* eslint-disable no-restricted-syntax */
import { isIframe } from '@moonpay-widget/common';
import Cookies, { CookieAttributes } from 'js-cookie';
import { isSameSiteNoneCompatible } from 'should-send-same-site-none';
import userAgent from './userAgent';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ga: any;
    gtag: any;
    OneTrust: any;
    OnetrustActiveGroups: string;
  }
}

export interface Cookie {
  name: string;
  value?: string;
  expiryDays?: number;
}

type StorageNameKey =
  | '_fbc'
  | '_fbp'
  | 'apiKey'
  | 'cookieTest'
  | 'countryCode'
  | 'countryCodeAlpha2'
  | 'csrfToken'
  | 'customerId'
  | 'defaultBaseCurrencyCode'
  | 'ipAddressCurrencyCode'
  | 'isEdgeSafariWebview'
  | 'isNftAccount'
  | 'ld_device_id'
  | 'ld_session_id'
  | 'OptanonConsent'
  | 'scamWarningViewed'
  | 'sessionId'
  | 'stateCode'
  | 'yellowCardIntroductionBannerSeen';

export default class StorageUtils {
  public static isUsingServiceWorker = false;

  public static setItem(
    name: StorageNameKey,
    value: string,
    options: CookieAttributes = {},
  ): void {
    Cookies.set(name, value, {
      ...this.getDefaultCookieOptions(),
      ...options,
    });
  }

  public static getItem(name: StorageNameKey): string | undefined {
    return Cookies.get(name);
  }

  public static getOrSetItem(
    name: StorageNameKey,
    value: string,
    options: CookieAttributes = {},
  ): string {
    const currentValue = this.getItem(name);
    if (typeof currentValue !== 'undefined') {
      return currentValue;
    }
    this.setItem(name, value, options);
    return value;
  }

  public static removeItem(name: string): void {
    return Cookies.remove(name, this.getDefaultCookieOptions());
  }

  private static getDefaultCookieOptions(): CookieAttributes {
    const shouldSameSite =
      process.env.REACT_APP_SAME_SITE_NONE === 'true' ||
      process.env.NODE_ENV === 'production';

    return {
      ...(navigator &&
        navigator.userAgent &&
        isSameSiteNoneCompatible(navigator.userAgent) && {
        sameSite: shouldSameSite ? 'none' : 'strict',
        secure: shouldSameSite,
      }),
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
    };
  }

  public static initialise(): void {
    const supportsServiceWorker = 'serviceWorker' in navigator;

    if (supportsServiceWorker) {
      navigator.serviceWorker.register('/tokenWorker.js').then(() => {
        this.isUsingServiceWorker = true;
      });
    }
  }

  public static async setCustomerToken(token: string): Promise<void> {
    if (this.isUsingServiceWorker && this.useServiceWorkerTokenStorage()) {
      navigator.serviceWorker.controller?.postMessage({
        type: 'SET_CUSTOMER_TOKEN',
        token,
      });
    }
  }

  public static async setCsrfToken(token: string): Promise<void> {
    if (this.isUsingServiceWorker && this.useServiceWorkerTokenStorage()) {
      navigator.serviceWorker.controller?.postMessage({
        type: 'SET_CSRF_TOKEN',
        token,
      });
    } else {
      this.setItem('csrfToken', token, { domain: '.moonpay.com' });
    }
  }

  public static useServiceWorkerTokenStorage(): boolean {
    return isIframe() && userAgent.isSafari;
  }

  public static clearCustomerTokens() {
    this.removeItem('csrfToken');
    this.removeItem('customerId');

    if (this.isUsingServiceWorker && this.useServiceWorkerTokenStorage()) {
      navigator.serviceWorker.controller?.postMessage({
        type: 'CLEAR_TOKENS'
      });
    }
  }
}
