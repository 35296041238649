import { ChangeEvent, useState } from 'react';

interface UseForm<T> {
  formValues: T;
  errors: Record<keyof T, string> | null;
  handleChangeText: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface UseFormParams<T> {
  initialValues: T;
  validate: (formValues: T) => Record<keyof T, string>;
}

const useForm = <T>({
  initialValues,
  validate,
}: UseFormParams<T>): UseForm<T> => {
  const [formValues, setFormValues] = useState<T>(initialValues);
  const [errors, setErrors] = useState<Record<keyof T, string> | null>(
    () => validate?.(initialValues) ?? null,
  );

  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as string;
    const value = event.target.value as string;

    const newFormValues = {
      ...formValues,
      [name]: value,
    };
    setFormValues(newFormValues);

    const validationResults = validate(newFormValues);
    setErrors(validationResults);
  };

  return {
    formValues,
    errors,
    handleChangeText,
  };
};

export default useForm;
