import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
  WalletMessageRequest,
  WalletNetwork,
  WalletNetworkToSymbolMap,
} from '@moonpay/login-common';
import { Wallet as EthersWallet, ethers } from 'ethers';
import { OnPromptChangeFunc } from 'src/messages/Message';
import addWalletMessageProxyEventListener from 'src/messages/walletProxy/addWalletMessageProxyEventListener';
import { ethAccounts } from 'src/messages/walletProxy/methods/accounts';
import { addNetwork } from 'src/messages/walletProxy/methods/addNetwork';
import { chainId } from 'src/messages/walletProxy/methods/chainId';
import { exportMnemonic } from 'src/messages/walletProxy/methods/exportMnemonic';
import { getBalance } from 'src/messages/walletProxy/methods/getBalance';
import { openPill } from 'src/messages/walletProxy/methods/openPill';
import { personalSign } from 'src/messages/walletProxy/methods/personalSign';
import { sendTransaction } from 'src/messages/walletProxy/methods/sendTransaction';
import { signTypedData } from 'src/messages/walletProxy/methods/signTypedData';
import { switchNetwork } from 'src/messages/walletProxy/methods/switchNetwork';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';
import {
  withPrompt,
  withoutPrompt,
} from 'src/messages/walletProxy/utils/withPrompt';
import { ErrorManager } from 'src/utils/errorManager';
import { Logger } from 'src/utils/logger';
import StorageUtils from 'src/utils/storage';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import { WalletService } from 'src/wallet/services/walletService';
import { EthereumChainSpec } from 'src/wallet/storage/ChainsStorage';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import {
  AbstractWallet,
  Bitcoin,
  Ethereum,
  match,
} from 'src/wallet/types/Wallet';

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);

export type RegisterWalletMessageProxyHandlerParams = {
  walletStorage: WalletStorage;
  promptRef: React.RefObject<any>;
  onPromptChange: OnPromptChangeFunc;
  onSuccess: (id: string, response: any) => void;
  onError: (id: string, error: any) => void;
  origins: string[];
  autoApprove: boolean;
  mnemonic?: string;
  noModal: boolean;
};

export type HandleWalletMessageProxyMessageParams = WalletMessageRequest &
  RegisterWalletMessageProxyHandlerParams;

const ETH_RESTRICTED_METHODS = [
  'eth_coinbase',
  'eth_accounts',
  'eth_requestAccounts',
  'personal_sign',
  'eth_signTypedData',
  'eth_signTypedData_v4',
  'eth_sendTransaction',
  'personal_ecRecover',
  'eth_signTransaction',
  'eth_sign',
  'eth_signTypedData_v3',
  'eth_signTypedData_v1',
  'eth_requestAccounts',
  'wallet_switchEthereumChain',
  'wallet_addEthereumChain',
];

const BTC_RESTRICTED_METHODS = [
  'btc_latestBlock',
  'btc_accounts',
  'btc_sendTransaction',
  'btc_getBalance',
  'btc_chainId',
  'btc_switchNetwork',
  // TODO: add balances, latest block, estimate gas (current implementations live in bitcoinsdk)
];

const ALL_NETWORKS_RESTRICTED_METHODS = [
  'wallet_disconnect',
  'export_mnemonic',
  'open_pill',
];

const RESTRICTED_METHODS = [
  // eth
  ...ETH_RESTRICTED_METHODS,

  // btc
  ...BTC_RESTRICTED_METHODS,

  // all networks
  ...ALL_NETWORKS_RESTRICTED_METHODS,
] as const;
type RestrictedMethod = typeof RESTRICTED_METHODS[number];

// DANGER: make sure changes make sense
const ALLOWED_HEADLESS_METHODS = [
  'eth_coinbase',
  'eth_accounts',
  'eth_requestAccounts',
];

const METHOD_IMPLEMENTATIONS: {
  [method in RestrictedMethod]?: MethodImplementation;
} = {
  // eth
  eth_coinbase: ethAccounts,
  eth_accounts: ethAccounts,
  eth_requestAccounts: ethAccounts,
  personal_sign: personalSign,
  eth_sign: personalSign,
  eth_signTypedData: signTypedData,
  eth_signTypedData_v4: signTypedData,
  eth_sendTransaction: sendTransaction,
  wallet_switchEthereumChain: switchNetwork,
  wallet_addEthereumChain: addNetwork,

  // btc
  // TODO: make sure ethAccounts is renamed correctly to accounts WAL-754
  btc_accounts: ethAccounts,
  btc_getBalance: getBalance,
  btc_chainId: chainId,
  btc_switchNetwork: switchNetwork,
  btc_sendTransaction: sendTransaction,

  // all networks
  export_mnemonic: exportMnemonic,
  open_pill: openPill,
};

/*
 * In Memory "Cached" Variables
 */
let activeWalletAddresses: {
  [network: string]: { [chainId: number]: string | null };
} = {
  [WalletNetwork.Ethereum]: {
    1: null,
    5: null,
    137: null,
    80001: null,
  },
  [WalletNetwork.Bitcoin]: {
    0: null,
    1: null,
  },
};
let activeWallets: {
  [network: string]: { [chainId: number]: AbstractWallet | undefined };
} = {
  [WalletNetwork.Ethereum]: {
    1: undefined,
    5: undefined,
    137: undefined,
    80001: undefined,
  },
  [WalletNetwork.Bitcoin]: {
    0: undefined,
    1: undefined,
  },
};

export const walletMessageProxyHandler = async ({
  walletStorage,
  id,
  promptRef,
  onPromptChange,
  onSuccess,
  onError,
  request,
  mnemonic,
  autoApprove,
  network,
  noModal,
}: HandleWalletMessageProxyMessageParams) => {
  console.debug('Receiving wallet message event contents', request);
  logger.logDebug(
    'Receiving wallet message event contents',
    'walletMessageProxyHandler',
    request,
  );
  try {
    if (!origin) {
      throw errorManager.getServerError(
        'walletMessageProxyHandler',
        `origin caller is required for responses`,
      );
    }

    if (!network) {
      network = WalletNetwork.Ethereum;
    }

    const networkSymbol = WalletNetworkToSymbolMap[network];

    // validate the network in the body is compatible with the method being called
    if (
      ETH_RESTRICTED_METHODS.includes(request.method) &&
      network !== WalletNetwork.Ethereum
    ) {
      throw errorManager.getServerError(
        'walletMessageProxyHandler',
        `Method ${request.method} is not supported on ${network}`,
        { network, requestMethod: request.method },
      );
    }
    if (
      BTC_RESTRICTED_METHODS.includes(request.method) &&
      network !== WalletNetwork.Bitcoin
    ) {
      throw errorManager.getServerError(
        'walletMessageProxyHandler',
        `Method ${request.method} is not supported on ${network}`,
        { network, requestMethod: request.method },
      );
    }

    const cryptoWallet = WalletService.cryptoWalletFactory(network);

    const chainId =
      walletStorage.activeChainId.getActiveChainIdByNetwork(network);
    const chain = walletStorage.chains.getChain(chainId.toString(), network);
    if (!chain) {
      throw errorManager.getServerError(
        'walletMessageProxyHandler',
        `chain required to handle request`,
        { network, requestMethod: request.method },
      );
    }

    let response = null;

    const isRestrictedMethod = RESTRICTED_METHODS.includes(
      request.method as RestrictedMethod,
    );

    const isConnectionRequest = [
      `${networkSymbol}_accounts`,
      `${networkSymbol}_requestAccounts`,
    ].includes(request.method);

    if (isRestrictedMethod) {
      let wallet: AbstractWallet | undefined;

      // if we have an active wallet address, and the request is eth_accounts,
      // we can skip the connection check and just return the address
      if (activeWalletAddresses[network]?.[chainId] && isConnectionRequest) {
        onSuccess(id, [activeWalletAddresses[network][chainId]]);
        return;
      }

      // if we have an active wallet, we can skip restoring the wallet
      if (activeWallets[network]?.[chainId]) {
        wallet = activeWallets[network][chainId];
      } else if (mnemonic) {
        wallet = (await cryptoWallet.createFromMnemonic(mnemonic, chainId))
          .data!;
      } else {
        wallet = await WalletService.restoreWallet(network, walletStorage);
      }

      if (!wallet) {
        throw errorManager.getServerError(
          'walletMessageProxyHandler',
          `wallet required to handle request`,
          { network, requestMethod: request.method },
        );
      }

      // only used for ethereum wallet requests
      // TODO: refactor this so that we don't do this for non evm networks
      const provider = WalletHelpers.getNetworkProvider(
        walletStorage.chains.value[WalletNetwork.Ethereum][
          walletStorage.activeChainId.value[WalletNetwork.Ethereum]
        ],
      );

      const ethersWallet = ethers.Wallet.fromMnemonic(
        wallet.mnemonic.phrase,
      ).connect(provider);

      // if we are using an AbstractWallet, we need to connect it to the provider
      if (wallet.type === WalletNetwork.Ethereum) {
        match(wallet as AbstractWallet, {
          Bitcoin: (btcWallet: Bitcoin) => {},
          Ethereum: async (ethWallet: Ethereum) => {
            await ethWallet.wallet.connect(provider);
          },
        });
      }

      // if we don't have an active wallet address, set it to the first address
      // in the wallet after hydrating it
      if (!activeWalletAddresses[network]?.[chainId]) {
        activeWalletAddresses[network][chainId] = wallet.address;
      }

      // if we don't have an active wallet, set it to the wallet after hydrating it
      if (!activeWallets[network]?.[chainId]) {
        activeWallets[network][chainId] = wallet;
      }

      const isDisconnectRequest = request.method === 'wallet_disconnect';
      if (isDisconnectRequest) {
        // Cleanup all logged in artifacts
        StorageUtils.clearCustomerTokens(); // clear cookies (invalidate API calls)
        activeWalletAddresses[network][chainId] = null; // remove active wallet address (wallet is not stored in memory)
        activeWallets[network][chainId] = undefined; // remove active wallet (wallet is not stored in memory)
        sessionStorage.removeItem('moonpay-wallet'); // remove encrypted wallet from session storage

        await walletStorage.connections.updateWalletConnection({
          address: wallet.address,
          chainId,
          origin,
          value: false,
          network,
        });

        onSuccess(id, true);
        return;
      }

      if (!autoApprove && !isConnectionRequest) {
        const isConnected =
          walletStorage.connections.checkWalletConnectionStatus({
            address: wallet.address,
            chainId,
            origin,
            network,
          });
        if (!isConnected) {
          throw new EthProviderRpcError(
            EthProviderRpcErrorCode.UserRejectedRequest,
          );
        }
      }

      // prompts
      const methodImpl =
        METHOD_IMPLEMENTATIONS[request.method as RestrictedMethod];
      if (!methodImpl) {
        throw new EthProviderRpcError(
          EthProviderRpcErrorCode.UnsupportedMethod,
          `Moonpay wallet does not support calling ${request.method}.`,
        );
      }

      // TODO: refactor so that we don't have to pass in ethers.wallet and domain wallet
      if (autoApprove && ALLOWED_HEADLESS_METHODS.includes(request.method)) {
        response = await withoutPrompt({
          request,
          walletStorage,
          wallet: ethersWallet as EthersWallet,
          abstractWallet: wallet as AbstractWallet,
          origin,
          method: methodImpl,
          network,
        });
      } else {
        response = await withPrompt({
          request,
          onPromptChange,
          walletStorage,
          promptRef,
          wallet: ethersWallet as EthersWallet,
          abstractWallet: wallet as AbstractWallet,
          origin,
          method: methodImpl,
          network,
          noModal,
        });
      }
    } else if (network === WalletNetwork.Ethereum) {
      const getNetworkProvider = (chain: EthereumChainSpec) => {
        // TODO: Only the first rpc url is used here but you can also setup fallbacks
        const rpcUrl = chain.rpcUrls[0];
        if (!rpcUrl) {
          throw errorManager.getServerError(
            'walletMessageProxyHandler',
            `rpc url required to get network provider`,
            { chain },
          );
        }
        return new ethers.providers.JsonRpcProvider({
          url: rpcUrl,
        });
      };

      const provider = getNetworkProvider(chain);
      response = await provider.send(request.method, request.params!);
    }

    onSuccess(id, response);
  } catch (e) {
    // We don't want to spam the alerts channel as all error (whether it be expected RPC ones) are thrown through here
    logger.logWarn('walletMessageProxyHandler', 'Error calling method', {
      error: e,
      method: request.method,
      params: request.params,
    });
    onError(id, e);
  }
};

/* istanbul ignore next */
// REASON:
// This code is a proxy for other code and should be tested from there or moved.
const registerWalletMessageProxyHandler = (
  params: RegisterWalletMessageProxyHandlerParams,
) => {
  addWalletMessageProxyEventListener(
    (payload: WalletMessageRequest) =>
      walletMessageProxyHandler({
        ...params,
        ...payload,
      }),
    params.origins,
  );
};

export default registerWalletMessageProxyHandler;
