import { WalletNetwork } from '@moonpay/login-common';
import { BaseStorage } from 'src/wallet/storage/BaseStorage';
import { EventTrackingUtils } from "../../utils/eventTracking";
import { UserWalletAnalyticsEvent } from "../../types/UserWalletAnalyticsEvent";

const STORAGE_KEY = 'connections';

export type NetworkConnections = { [network in WalletNetwork]: Connections };
export type Connections = { [address: string]: ChainConnections };
export type ChainConnections = { [chainId: string]: OriginConnections };
export type OriginConnections = { [origin: string]: boolean };

export class ConnectionsStorage extends BaseStorage<NetworkConnections> {
  private lastSentCancelEvent?: Date;

  constructor() {
    super({ key: STORAGE_KEY, defaultValue: {
      [WalletNetwork.Ethereum]: {},
      [WalletNetwork.Bitcoin]: {},
    } });
  }

  reset() {
    if (!this.value[WalletNetwork.Ethereum]) {
      this.update(() => ({
        [WalletNetwork.Ethereum]: {},
        [WalletNetwork.Bitcoin]: {},
      }));
    }
  }

  updateConnection({
    address,
    chainId,
    origin,
    confirmed,
    network,
  }: {
    address: string;
    chainId: string;
    origin: string;
    confirmed: boolean;
    network: WalletNetwork;
  }) {
    return this.update((connections) => {
      const newConnections = { ...connections };


      if (!(address in newConnections)) {
        newConnections[network][address] = {};
      }

      if (!(chainId in newConnections[network][address])) {
        newConnections[network][address][chainId] = {};
      }

      newConnections[network][address][chainId][origin] = confirmed;

      return newConnections;
    });
  }

  removeConnection({
    address,
    chainId,
    origin,
    network,
  }: {
    address: string;
    chainId: string;
    origin: string;
    network: WalletNetwork;
  }) {
    return this.update((connections) => {
      const newConnections = { ...connections };

      if (
        address in newConnections &&
        chainId in newConnections[network][address] &&
        origin in newConnections[network][address][chainId]
      ) {
        delete newConnections[network][address][chainId][origin];
      }
  
      return newConnections;
    });
  }
  
     

   updateWalletConnection({
    address,
    chainId,
    origin,
    value,
    network,
  }: {
    address: string;
    chainId: number;
    origin: string;
    value: boolean;
    network: WalletNetwork;
  }) {

    let oldValue = this.checkWalletConnectionStatus({ address, chainId, origin, network });
    this.updateConnection({
      address,
      chainId: chainId.toString(),
      origin,
      confirmed: value,
      network,
    });
    let newValue = this.checkWalletConnectionStatus({ address, chainId, origin, network });

    if (!oldValue && newValue) {
      EventTrackingUtils.trackEvent(UserWalletAnalyticsEvent.approveConnectionCompleted, address, network, { success: true });
    }
    // We've had to put this spam protection in place because the wallet is sending multiple rejection events back to
    // itself with no way of telling when the first one came in (unlike approvals which go from local origin false to true)
    // This ensures only one cancellation request is recorded during a period of spam
    const isRepeatSpamRejection = (new Date().getTime() - (this.lastSentCancelEvent?.getTime() ?? 0)) < 50;
    if (!value && !isRepeatSpamRejection) {
      EventTrackingUtils.trackEvent(UserWalletAnalyticsEvent.approveConnectionCancelled, address, network, { success: true });
      this.lastSentCancelEvent = new Date();
    }
  }

  checkWalletConnectionStatus({
    address,
    chainId,
    origin,
    network,
  }: {
    address: string;
    chainId: number;
    origin: string;
    network: WalletNetwork;
  }) {
    return this.value[network][address]?.[chainId.toString()]?.[origin] ?? false;
  }
}
