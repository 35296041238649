import { ThemeProvider } from '@moonpay-widget/ui-kit';
import { createRef, RefObject } from 'react';
import ReactDOM from 'react-dom';
import SecureWallet from 'src/components/SecureWallet';
import finalizeDhwFlow from 'src/moonpayApi/finalizeDhwFlow';
import StorageUtils from 'src/utils/storage';
import { WalletService } from 'src/wallet/services/walletService';
import './i18n';
import './index.css';

StorageUtils.initialise();

const promptRef: RefObject<any> = createRef();

WalletService.bootstrapSecureWallet(promptRef).then(
  ({ uxMode, walletStorage, theme }) => {
    ReactDOM.render(
      <ThemeProvider themeConfig={theme}>
        <SecureWallet
          uxMode={uxMode}
          walletStorage={walletStorage}
          onWalletBackedUpSuccess={finalizeDhwFlow}
          promptRef={promptRef}
        />
      </ThemeProvider>,
      document.getElementById('root'),
    );
  },
);
