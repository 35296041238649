import { WalletNetwork } from '@moonpay/login-common';
import environment from 'src/environment';
import { ChainsData } from 'src/wallet/storage/ChainsStorage';

const {
  ethereumMainnetRpcProviderUrl,
  ethereumGoerliRpcProviderUrl,
  polygonMainnetRpcProviderUrl,
  polygonTestnetRpcProviderUrl,
} = environment;

const DEFAULT_CHAINS: ChainsData = {
  [WalletNetwork.Ethereum]: {
    '1': {
      chainId: '0x1',
      chainName: 'Ethereum Mainnet',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [ethereumMainnetRpcProviderUrl],
      blockExplorerUrls: ['https://etherscan.io'],
    },
    '137': {
      chainId: '0x89',
      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [polygonMainnetRpcProviderUrl],
      blockExplorerUrls: ['https://polygonscan.com'],
    },
    '5': {
      chainId: '0x5',
      chainName: 'Goerli',
      nativeCurrency: {
        name: 'Goerli Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [ethereumGoerliRpcProviderUrl],
      blockExplorerUrls: ['https://goerli.etherscan.io'],
    },
    '80001': {
      chainId: '0x13881',
      chainName: 'Mumbai',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [polygonTestnetRpcProviderUrl],
      blockExplorerUrls: ['https://mumbai.polygonscan.com'],
    },
  },
  [WalletNetwork.Bitcoin]: {
    '0': {
      chainId: '0x0',
      chainName: 'Bitcoin Mainnet',
      nativeCurrency: {
        name: 'Bitcoin',
        symbol: 'BTC',
        decimals: 8,
      },
      rpcUrls: [],
    },
    '1': {
      chainId: '0x1',
      chainName: 'Bitcoin Testnet',
      nativeCurrency: {
        name: 'Bitcoin',
        symbol: 'BTC',
        decimals: 8,
      },
      rpcUrls: [],
    },
  },
};

export default DEFAULT_CHAINS;
