import axios from 'axios';

type ExtensionValue = string | number | boolean | null | undefined;

export interface TokenInfo {
  readonly chainId: number;
  readonly address: string;
  readonly name: string;
  readonly decimals: number;
  readonly symbol: string;
  readonly logoURI?: string;
  readonly tags?: string[];
  readonly extensions?: {
    readonly [key: string]:
      | {
          [key: string]:
            | {
                [key: string]: ExtensionValue;
              }
            | ExtensionValue;
        }
      | ExtensionValue;
  };
}

export interface Version {
  readonly major: number;
  readonly minor: number;
  readonly patch: number;
}

export interface Tags {
  readonly [tagId: string]: {
    readonly name: string;
    readonly description: string;
  };
}

export interface TokenList {
  readonly name: string;
  readonly timestamp: string;
  readonly version: Version;
  readonly tokens: TokenInfo[];
  readonly keywords?: string[];
  readonly tags?: Tags;
  readonly logoURI?: string;
}

const TOKEN_LIST = {
  name: 'MoonPay Token List',
  timestamp: '2023-01-01T00:00:00.000Z',
  version: { major: 1, minor: 0, patch: 0 },
  tokens: [
    {
      chainId: undefined,
      address: undefined,
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/2518/large/weth.png',
    },
    {
      chainId: 5,
      address: '0xb3A16C2B68BBB0111EbD27871a5934b949837D95',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/2518/large/weth.png',
    },
    {
      chainId: 80001,
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/2518/large/weth.png',
    },
    {
      chainId: 5,
      address: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI:
        'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png',
    },
    {
      chainId: 80001,
      address: '0x1Af2844A588759D0DE58abD568ADD96e4b3daF3A',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI:
        'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png',
    },
    {
      chainId: 5,
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
    },
  ],
};

const TOKEN_LIST_URL = 'https://cloudflare-ipfs.com/ipns/tokens.uniswap.org';
let cachedTokenList: TokenList;

// TODO: store in local storage? and call on wallet startup?
export async function getTokenList() {
  if (cachedTokenList) return cachedTokenList;
  const tokenList = (await axios.get(TOKEN_LIST_URL)).data;
  const tokens = tokenList.tokens.concat(TOKEN_LIST.tokens);
  cachedTokenList = { ...tokenList, tokens };
  return cachedTokenList;
}
