import executeGraphQLQuery from '.';
import { GetEncryptionKeyResponse } from '../wallet/types/apiResponses';
import queries from './queries';

const getEncryptionKey = async (csrfToken: string) => {
  const { data: encryptionKey } = await executeGraphQLQuery<
    GetEncryptionKeyResponse['walletEncryptionKey']
  >(csrfToken, queries.walletEncryptionKey);

  return encryptionKey;
};

export default getEncryptionKey;
