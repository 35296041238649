const isAndroid = (): boolean =>
  window.navigator.userAgent.toLowerCase().indexOf('android') >= 0;

const isIOS = (): boolean =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

const isSafari =
  window.navigator.userAgent.includes('Safari') &&
  !window.navigator.userAgent.includes('Chrome');

const isBrave = 'brave' in navigator;

const module = {
  isAndroid,
  isIOS,
  isSafari,
  isBrave,
};

export default module;
