import {
  EthProviderRpcError,
  EthProviderRpcErrorCode,
  ProviderRequest,
  WalletNetwork,
} from '@moonpay/login-common';
import { Wallet } from 'ethers';
import { PromptRef } from 'src/components/PromptContainer/types';
import { OnPromptChangeFunc } from 'src/messages/Message';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { AbstractWallet } from 'src/wallet/types/Wallet';

async function withoutPrompt({
  request,
  walletStorage,
  abstractWallet,
  wallet,
  origin,
  method,
  network,
}: {
  request: ProviderRequest;
  walletStorage: WalletStorage;
  abstractWallet: AbstractWallet;
  wallet: Wallet;
  origin: string;
  method: MethodImplementation;
  network: WalletNetwork;
}) {
  const promptRequest = {
    request,
    origin,
    wallet,
    abstractWallet,
    walletStorage,
    network,
  };

  if (method.validate) await method.validate(promptRequest);
  const response = await method.execute(promptRequest);
  if (method.postExecute) {
    await method.postExecute(promptRequest);
  }
  return response;
}

async function withPrompt({
  request,
  walletStorage,
  onPromptChange,
  promptRef,
  wallet,
  abstractWallet,
  origin,
  method,
  network,
  noModal,
}: {
  request: ProviderRequest;
  walletStorage: WalletStorage;
  onPromptChange: OnPromptChangeFunc;
  promptRef: PromptRef;
  abstractWallet: AbstractWallet;
  wallet: Wallet;
  origin: string;
  method: MethodImplementation;
  network: WalletNetwork;
  noModal: boolean;
}) {
  const promptRequest = {
    request,
    origin,
    wallet,
    abstractWallet,
    walletStorage,
    network,
  };

  if (method.validate) {
    await method.validate(promptRequest);
  }

  const promptResult = await method.prompt(promptRequest).catch((e) => {
    throw new EthProviderRpcError(
      EthProviderRpcErrorCode.IncorrectArgument,
      e.message,
    );
  });

  if (promptResult) {
    const {
      title,
      message,
      component,
      accountLogo,
      approveButtonText,
      aboveButtonsText,
      cancelButtonText,
      approvalDisabled,
      approvalHidden,
      fitToContent,
      cancelButtonDisabled,
      notifyPromptClosedOnApprove,
      account,
      connection,
    } = promptResult;
    // If no props - we don't need to prompt

    onPromptChange('ready', network);

    const accepted = await promptRef.current?.promptUser({
      title,
      message,
      component,
      url: origin,
      accountLogo,
      approveButtonText,
      aboveButtonsText,
      cancelButtonText,
      approvalDisabled,
      approvalHidden,
      fitToContent,
      noModal,
      cancelButtonDisabled,
      notifyPromptClosedOnApprove,
      account,
      connection,
    });

    onPromptChange(accepted ? 'approved' : 'rejected', network);

    if (!accepted) {
      if (method.reject) {
        await method.reject(promptRequest);
      }
      throw new EthProviderRpcError(
        EthProviderRpcErrorCode.UserRejectedRequest,
        'User rejected request',
      );
    }
  }

  const response = await method.execute(promptRequest);
  if (method.postExecute) {
    await method.postExecute(promptRequest);
  }
  return response;
}

export { withPrompt, withoutPrompt };
