import { CreateWalletPayload } from '@moonpay-widget/dhw';
import {
  WalletDetail,
  WalletDetails,
  WalletNetwork,
} from '@moonpay/login-common';
import { switchActiveChainId } from 'src/utils/activeChain';
import StorageUtils from 'src/utils/storage';
import { WalletService } from 'src/wallet/services/walletService';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { KmsWalletProvider } from 'src/wallet/walletProvider/kms/KmsWalletProvider';
import { Logger } from '../../utils/logger';
import addCreateWalletMessageEventListener from './addCreateWalletMessageEventListener';

const logger = new Logger(__filename);

export type RegisterCreateWalletMessageHandlerParams = {
  walletStorage: WalletStorage;
  onSuccess: (walletAddresses: WalletDetails) => void;
  onError: (error: any) => void;
  origins: string[];
};

export const createWalletMessageHandler = async (
  params: RegisterCreateWalletMessageHandlerParams,
  payload: CreateWalletPayload,
) => {
  const { walletStorage, onSuccess, onError } = params;

  const csrfTokenPayload = payload.csrfToken;
  const customerTokenPayload = payload.customerToken as string;
  const isMainnet = payload.isMainnet !== undefined ? payload.isMainnet : true;

  // if no networks are specified, default to Ethereum and Bitcoin
  const networks = payload.networks || [
    WalletNetwork.Ethereum,
    WalletNetwork.Bitcoin,
  ];

  // Store csrf and customer tokens to the service worker/cookies, if necessary
  if (csrfTokenPayload) StorageUtils.setCsrfToken(csrfTokenPayload);
  if (customerTokenPayload) StorageUtils.setCustomerToken(customerTokenPayload);

  const wallets: WalletDetail[] = [];

  for (const network of networks) {
    // Iterate over the list of networks
    let encryptedWallet: string;
    let walletAddress: string;

    try {
      switchActiveChainId(walletStorage, network, isMainnet);

      const wallet = await WalletService.restoreWallet(network, walletStorage);

      if (wallet) {
        wallets.push({
          address: wallet.address,
          network,
        });

        continue;
      }

      const kmsProvider = new KmsWalletProvider();
      const createResult = await kmsProvider.create(network, walletStorage);
      walletAddress = createResult.walletAddress;
      encryptedWallet = createResult.walletToStore;

      walletStorage.encryptedWallet.set(encryptedWallet);

      wallets.push({
        address: walletAddress,
        network,
      });
    } catch (e: any) {
      logger.logError(
        'createWalletMessageHandler',
        'Error occurred whilst registering the createWalletMessageHandler',
        { message: e.message },
      );
      onError(e);
    }
  }

  if (wallets.length > 0) {
    // Only call onSuccess if wallets were successfully created
    onSuccess({ wallets });
  } else {
    onError(new Error('No wallets were restored or created'));
  }
};

const registerCreateWalletMessageHandler = (
  params: RegisterCreateWalletMessageHandlerParams,
) => {
  addCreateWalletMessageEventListener(
    (payload: CreateWalletPayload) =>
      createWalletMessageHandler(params, payload),
    params.origins,
  );
};

export default registerCreateWalletMessageHandler;
