import { ConnectionRequestIcon } from '@moonpay-widget/ui-kit';
import { WalletNetworkToSymbolMap } from '@moonpay/login-common';
import i18n, { strings } from 'src/i18n';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { ErrorManager } from 'src/utils/errorManager';
import { EventTrackingUtils } from 'src/utils/eventTracking';
import { Logger } from 'src/utils/logger';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import { Bitcoin, Ethereum, match } from 'src/wallet/types/Wallet';
import {
  WalletConnectionInput,
  checkCustomerAccountConnection,
  getAccountTermsAndPrivacy,
  setWalletConnection,
} from 'src/wallet/walletProvider/kms/kmsApi';
import { UserWalletAnalyticsEvent } from '../../../../types/UserWalletAnalyticsEvent';
import ConnectWallet from './ConnectWallet';

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);

export async function ethAccountsValidate(req: PromptRequest) {
  const networkSymbol = WalletNetworkToSymbolMap[req.network];

  if (
    req.request.method !== `${networkSymbol}_accounts` &&
    req.request.method !== `${networkSymbol}_requestAccounts` &&
    req.request.method !== `${networkSymbol}_coinbase`
  )
    throw errorManager.getServerError(
      'ethAccountsValidate',
      `Invalid request method`,
    );

  if (req.request.params && req.request.params.length > 0) {
    throw errorManager.getServerError(
      'ethAccountsValidate',
      `Invalid request params`,
    );
  }
}

export async function ethAccountsPostExecute({
  network,
  abstractWallet,
}: PromptRequest) {
  const { searchParams } = new URL(window.location.href);
  const { apiKey } = WalletHelpers.parseQueryParams(searchParams);

  // unwrap wallet and get address
  const address = match<string>(abstractWallet, {
    Bitcoin: (btcWallet: Bitcoin) => btcWallet.address,
    Ethereum: (ethWallet: Ethereum) => ethWallet.wallet.address,
  });

  // if address is an error, throw
  if (address instanceof Error) {
    EventTrackingUtils.trackEvent(
      UserWalletAnalyticsEvent.approveConnectionCompleted,
      abstractWallet.address,
      network,
      { success: false },
    );
    throw errorManager.getServerError(
      'ethAccountsPostExecute',
      `Invalid network`,
    );
  }

  const walletConnection: WalletConnectionInput = {
    walletAddress: address,
    piiConsent: localStorage.getItem('pii-consent') === 'true' ? true : false,
  };
  await setWalletConnection(apiKey, walletConnection);
}

export async function ethAccountsExecute({
  request,
  walletStorage,
  origin,
  network,
  abstractWallet,
}: PromptRequest) {
  // unwrap wallet and get address
  const address = match<string>(abstractWallet, {
    Bitcoin: (btcWallet: Bitcoin) => btcWallet.address,
    Ethereum: (ethWallet: Ethereum) => ethWallet.wallet.address,
  });

  // if address is an error, throw
  if (address instanceof Error) {
    EventTrackingUtils.trackEvent(
      UserWalletAnalyticsEvent.approveConnectionCompleted,
      abstractWallet.address,
      network,
      { success: false },
    );
    throw errorManager.getServerError('ethAccountsExecute', `Invalid network`);
  }

  // update wallet connection since we know the call was successful
  await walletStorage.connections.updateWalletConnection({
    address,
    chainId: walletStorage.activeChainId.getActiveChainIdByNetwork(network),
    origin,
    value: true,
    network,
  });

  // return address or array of addresses based on request method
  if (request.method === 'eth_coinbase') {
    return address;
  }
  return [address];
}

export async function ethAccountsReject({
  walletStorage,
  origin,
  network,
  abstractWallet,
}: PromptRequest) {
  // unwrap wallet and get address
  const address = match<string>(abstractWallet, {
    Bitcoin: (btcWallet: Bitcoin) => btcWallet.address,
    Ethereum: (ethWallet: Ethereum) => ethWallet.wallet.address,
  });
  if (address instanceof Error)
    throw errorManager.getServerError('ethAccountsReject', `Invalid network`);

  try {
    await walletStorage.connections.updateWalletConnection({
      address,
      chainId: walletStorage.activeChainId.getActiveChainIdByNetwork(network),
      origin,
      value: false,
      network,
    });
  } catch (error) {
    logger.logError('ethAccountsReject', 'Failed to reject wallet connection', {
      error,
      address,
      origin,
      network,
    });
    EventTrackingUtils.trackEvent(
      UserWalletAnalyticsEvent.approveConnectionCancelled,
      address,
      network,
      { success: false },
    );
  }
}

export async function ethAccountsPrompt({
  walletStorage,
  origin,
  network,
  abstractWallet,
}: PromptRequest) {
  const { searchParams } = new URL(window.location.href);
  const { apiKey } = WalletHelpers.parseQueryParams(searchParams);

  // unwrap wallet and get address
  const address = match<string>(abstractWallet, {
    Bitcoin: (btcWallet: Bitcoin) => btcWallet.address,
    Ethereum: (ethWallet: Ethereum) => ethWallet.wallet.address,
  });
  if (address instanceof Error)
    throw errorManager.getServerError('ethAccountsPrompt', `Invalid network`);

  const walletConnected = walletStorage.connections.checkWalletConnectionStatus(
    {
      address,
      chainId: walletStorage.activeChainId.getActiveChainIdByNetwork(network),
      origin,
      network,
    },
  );

  const customerAccountConnection = await checkCustomerAccountConnection(
    apiKey,
  );
  const accountDetails = await getAccountTermsAndPrivacy(apiKey);

  const termsOrPrivacyExistsAndNotAccepted =
    (accountDetails.privacyLink &&
      !customerAccountConnection.privacyPolicyAccepted) ||
    (accountDetails.termsLink && !customerAccountConnection.tosAccepted)
      ? true
      : false;

  const promptUserForConnection =
    !customerAccountConnection.connected || termsOrPrivacyExistsAndNotAccepted
      ? true
      : false;

  if (!walletConnected || promptUserForConnection) {
    return {
      title: i18n.t(strings.prompts.connectionRequest.title),
      accountLogo: ConnectionRequestIcon,
      component: <ConnectWallet />,
      account: accountDetails,
      connection: customerAccountConnection,
      address: address,
      aboveButtonsText: i18n.t(strings.prompts.connectionRequest.footer.title),
      approveButtonText: i18n.t(
        strings.prompts.connectionRequest.footer.approvalButtonText,
      ),
      cancelButtonText: i18n.t(
        strings.prompts.connectionRequest.footer.cancelButtonText,
      ),
      approvalDisabled: termsOrPrivacyExistsAndNotAccepted,
    };
  }
}
