import { chainIdExecute, chainIdPrompt, chainIdReject, chainIdValidate } from "src/messages/walletProxy/methods/chainId/chainId";
import { MethodImplementation } from "src/messages/walletProxy/methods/types";

const chainId: MethodImplementation = {
    validate: chainIdValidate,
    prompt: chainIdPrompt,
    execute: chainIdExecute,
    reject: chainIdReject,
}

export { chainId };