import {
  CreateWalletAddressResponse,
  CreateWalletBackupResponse,
  CreateWalletResponse,
  GetEncryptionKeyResponse,
} from '../wallet/types/apiResponses';

const walletEncryptionKeyQuery = () => `
  query {
    walletEncryptionKey {
      id, key, iv, algorithm
    }
  }
`;

const createWalletQuery = () => `
  mutation {
    createWallet {
        walletEncryptionKey {
            id, key, iv, algorithm
        }
    }
  }
`;

const createWalletAddressQuery = (
  queryParameters: [walletAddress: string, chain: string],
) => {
  const [walletAddress, chain] = queryParameters;
  return `
  mutation {
    createWalletAddress(walletAddress: "${walletAddress}", chain: "${chain}") {
      success
    }
  }
`;
};

const createWalletBackupQuery = (
  queryParameters: [
    walletAddress: string,
    network: string,
    provider: string,
    backupMethod: 'Google' | 'Manual',
  ],
) => {
  const [walletAddress, network, provider, backupMethod] = queryParameters;
  return `
  mutation {
    createWalletBackup(walletAddress: "${walletAddress}", provider: "${provider}", backupMethod: "${backupMethod}", chain: "${network}") {
      success
    }
}
`;
};

export type QueryInfo<TQqueryParameters> = {
  query: (parameters: TQqueryParameters) => string;
  getter: (data: any) => any;
};

const module = {
  walletEncryptionKey: {
    query: walletEncryptionKeyQuery,
    getter: (data: GetEncryptionKeyResponse) => data.walletEncryptionKey,
  } as QueryInfo<[]>,

  createWallet: {
    query: createWalletQuery,
    getter: (data: CreateWalletResponse) =>
      data.createWallet.walletEncryptionKey,
  } as QueryInfo<[]>,

  createWalletAddress: {
    query: createWalletAddressQuery,
    getter: (data: CreateWalletAddressResponse) =>
      data.createWalletAddress.success,
  } as QueryInfo<[string, string]>,

  createWalletBackup: {
    query: createWalletBackupQuery,
    getter: (data: CreateWalletBackupResponse) =>
      data.createWalletBackup.success,
  } as QueryInfo<[string, string, string, 'Google' | 'Manual']>,
};

export default module;
