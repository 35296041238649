import {
  EthProviderRpcError,
  WalletDetails,
  WalletMessageType,
  WalletNetwork,
} from '@moonpay/login-common';
import { RefObject } from 'react';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { Logger } from '../utils/logger';
import {
  sendMessageToParent,
  sendWalletMessage,
  sendWalletReply,
} from './Message';
import registerCreateWalletMessageHandler from './walletCreation/registerCreateWalletMessageHandler';
import registerGetWalletMessageHandler from './walletCreation/registerGetWalletMessageHandler';
import registerWalletMessageProxyHandler from './walletProxy/registerWalletMessageProxyHandler';

const logger = new Logger(__filename);

type Args = {
  walletStorage: WalletStorage;
  searchParams: URLSearchParams;
  promptRef: RefObject<HTMLElement>;
  origins: string[];
  autoApprove: boolean;
  initialNetworkEth: number | boolean;
  initialNetworkBtc: number | boolean;
  noModal: boolean;
};

const registerHandlers = ({
  walletStorage,
  searchParams,
  promptRef,
  origins,
  autoApprove,
  initialNetworkEth,
  initialNetworkBtc,
  noModal,
}: Args) => {
  const initialChainIds = {
    [WalletNetwork.Ethereum]:
      typeof initialNetworkEth === 'number'
        ? initialNetworkEth
        : walletStorage.activeChainId.getActiveChainIdByNetwork(
            WalletNetwork.Ethereum,
          ),
    [WalletNetwork.Bitcoin]:
      typeof initialNetworkBtc === 'number'
        ? initialNetworkBtc
        : walletStorage.activeChainId.getActiveChainIdByNetwork(
            WalletNetwork.Bitcoin,
          ),
  };
  walletStorage.activeChainId.set(initialChainIds);

  registerWalletMessageProxyHandler({
    walletStorage,
    promptRef,
    onPromptChange: (action: string, network: WalletNetwork) => {
      sendWalletMessage(
        {
          type: WalletMessageType.PROMPT_CHANGE,
          result: {
            action,
            network,
          },
        },
        origins,
      );
    },
    onSuccess: (id: string, response: any) => {
      sendWalletReply(id, { result: response }, origins);
    },
    onError: (id: string, error: EthProviderRpcError) => {
      // emit a warning since explicit errors are logged upstream
      //
      // note: even when we `logError` early we still `throw` the
      // error so its resurfaced here. In order to avoid double logging
      // the error we should throw a warning here (or avoid all together)
      // TODO: possibly extend error to flag if logged already
      //
      logger.logWarn(
        'registerWalletMessageProxyHandler',
        'Error occurred when handling wallet message proxy',
        {
          error,
          id,
          origins,
          chainId: walletStorage.activeChainId.getActiveChains(),
          searchParams,
        },
      );
      sendWalletReply(
        id,
        {
          error,
        },
        origins,
      );
    },
    origins,
    autoApprove,
    // TODO mnemonic for test only purposes, delete it later
    mnemonic: searchParams.get('mnemonic') || '',
    noModal,
  });

  registerCreateWalletMessageHandler({
    walletStorage,
    onSuccess: (wallets: WalletDetails) => {
      wallets.wallets.forEach((wallet) => {
        logger.logInfo(
          'registerCreateWalletMessageHandler',
          `Successfully created wallet with address ${wallet.address} on ${wallet.network}`,
          {
            wallet,
            chainId: walletStorage.activeChainId.getActiveChains(),
            searchParams,
          },
        );
      });

      sendMessageToParent<WalletDetails>(
        'create-wallet-success',
        origins,
        wallets,
      );
    },
    onError: (error: EthProviderRpcError) => {
      // emit a warning since explicit errors are logged upstream
      //
      // note: even when we `logError` early we still `throw` the
      // error so its resurfaced here. In order to avoid double logging
      // the error we should throw a warning here (or avoid all together)
      // TODO: possibly extend error to flag if logged already
      //
      logger.logWarn(
        'registerCreateWalletMessageHandler',
        'Error occurred when handling create wallet message',
        {
          error,
          origins,
          chainId: walletStorage.activeChainId.getActiveChains(),
          searchParams,
        },
      );
      sendMessageToParent('create-wallet-error', origins, {
        error,
      });
    },
    origins,
  });

  registerGetWalletMessageHandler({
    walletStorage,
    // TODO: improve this; returns undefined if wallets created already - Josh
    onSuccess: (wallets: WalletDetails | undefined) => {
      sendMessageToParent<WalletDetails>(
        'get-wallet-success',
        origins,
        wallets,
      );
    },
    onError: (error: EthProviderRpcError) => {
      // emit a warning since explicit errors are logged upstream
      //
      // note: even when we `logError` early we still `throw` the
      // error so its resurfaced here. In order to avoid double logging
      // the error we should throw a warning here (or avoid all together)
      // TODO: possibly extend error to flag if logged already
      //
      logger.logWarn(
        'registerGetWalletMessageHandler',
        'Error occurred when handling get wallet message',
        {
          error: {
            name: error.name,
            message: error.message,
            stack: error.stack,
          },
          origins,
          chainId: walletStorage.activeChainId.getActiveChains(),
          searchParams: searchParams.toString(),
        },
      );
      sendMessageToParent('get-wallet-error', origins, {
        error,
      });
    },
    origins,
  });
};

export default registerHandlers;
