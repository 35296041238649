import { ChevronDownIcon, CopyIcon, Icon } from '@moonpay-widget/ui-kit';
import { FunctionComponent } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PillView } from 'src/messages/walletProxy/methods/openPill/PillPrompt';

export type WalletIconProps = {
  network: number;
  logo: FunctionComponent;
  changeViewCallback: (view: PillView) => void;
};

const NetworkButton = ({
  network,
  logo,
  changeViewCallback,
}: WalletIconProps) => {
  return (
    <div
      className="flex items-center rounded-full px-3 h-10 cursor-pointer space-x-2"
      style={{ backgroundColor: '#00000020' }}
      onClick={() => changeViewCallback(PillView.SWITCH_NETWORK)}
    >
      <div className="flex items-center justify-center w-6 h-6">{logo}</div>
      <Icon icon={ChevronDownIcon} />
    </div>
  );
};

const AddressComponent = ({ address }: { address: string }) => {
  return (
    <div
      className="flex items-center rounded-full px-3 h-10 cursor-pointer space-x-2"
      style={{ backgroundColor: '#00000020', color: '#fff' }}
    >
      <div className="w-5 h-5 bg-moon-yellow rounded-full flex-none order-0"></div>
      <span>{address.slice(0, 4)}</span>
      <strong>...</strong>
      <span>{address.slice(-4)}</span>
      <CopyToClipboard text={address}>
        <button type="button">
          <CopyIcon size={12} />
        </button>
      </CopyToClipboard>
    </div>
  );
};

const OptionsComponent = ({ changeViewCallback }: any) => {
  return (
    <div
      className="flex items-center rounded-full px-3 h-10 cursor-pointer space-x-2"
      style={{ backgroundColor: '#00000020' }}
      onClick={() => changeViewCallback(PillView.MORE_ACTIONS)}
    >
      <svg
        width="16"
        height="4"
        viewBox="0 0 16 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="2" cy="2" r="2" fill="#fff" />
        <circle cx="8" cy="2" r="2" fill="#fff" />
        <circle cx="14" cy="2" r="2" fill="#fff" />
      </svg>
    </div>
  );
};

export type PillHeaderProps = {
  address: string;
  currentNetwork: number;
  currentNetworkLogo: FunctionComponent;
  changeViewCallback: (view: PillView) => void;
};

/**
 * This is the header of the wallet it contains the user icon, username, and wallet address
 */
export const PillHeader = ({
  address,
  currentNetwork,
  currentNetworkLogo,
  changeViewCallback,
}: PillHeaderProps) => {
  return (
    <div className="w-full flex justify-between px-8 pt-8 pb-4">
      <NetworkButton
        network={currentNetwork}
        logo={currentNetworkLogo}
        changeViewCallback={changeViewCallback}
      />
      <AddressComponent address={address} />
      <OptionsComponent changeViewCallback={changeViewCallback} />
    </div>
  );
};

export type ChangeNetworkHeaderProps = {
  changeViewCallback: (view: PillView) => void;
  title: string;
};

export const MenuItemHeader = ({
  changeViewCallback,
  title,
}: ChangeNetworkHeaderProps) => {
  return (
    <div className="w-full flex justify-between px-8 pt-8 pb-4">
      <div
        className="flex items-center justify-center w-10 h-10 bg-gray-800 rounded-full flex-shrink-0 mr-1 cursor-pointer"
        onClick={() => changeViewCallback(PillView.MAIN)}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00016 0.666504L0.666829 8.00084L8.00016 15.3342"
            stroke="#C4C4C4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.666992 8.00084H15.3337"
            stroke="#C4C4C4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <span
        style={{
          fontWeight: 800,
          fontSize: '1.60rem',
        }}
      >
        {title}
      </span>

      <div className="flex items-center justify-center w-10 h-10 flex-shrink-0"></div>
    </div>
  );
};
