import { Logger } from './logger';

export enum ErrorType {
  Client,
  Server,
  Database,
  Validation,
  Unknown,
  Ignored,
  Silent,
  Warn,
}

export class HandledError extends Error {
  constructor(
    public type: ErrorType,
    public filename: string,
    public functionName: string,
    public message: string,
    public data?: any,
    public statusCode?: number,
  ) {
    super(message);
  }
}

export class ErrorManager {
  private readonly filename: string;
  private logger: Logger;

  constructor(filename: string) {
    this.filename = this.getFilename(filename);
    this.logger = new Logger(this.filename);
  }

  public getClientError(
    functionName: string,
    message: string,
    data?: unknown,
    statusCode?: number,
  ): HandledError {
    return this.getError(
      ErrorType.Client,
      functionName,
      message,
      data,
      statusCode,
    );
  }

  public getServerError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Server, functionName, message, data);
  }

  public getSilentError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Silent, functionName, message, data);
  }

  public getWarnError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Warn, functionName, message, data);
  }

  public getDatabaseError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Database, functionName, message, data);
  }

  public getValidationError(
    functionName: string,
    message: string,
    data?: unknown,
  ): HandledError {
    return this.getError(ErrorType.Validation, functionName, message, data);
  }

  private getError(
    type: ErrorType,
    functionName: string,
    message: string,
    data?: any,
    statusCode?: number,
  ): HandledError {
    if (![ErrorType.Silent, ErrorType.Warn].includes(type)) {
      if (type === ErrorType.Client || type === ErrorType.Validation) {
        this.logger.logDebug(functionName, message, data);
      } else {
        this.logger.logError(functionName, message, data);
      }
    }
    if (type === ErrorType.Warn) {
      this.logger.logWarn(functionName, message, data);
    }

    return new HandledError(
      type,
      this.filename,
      functionName,
      message,
      data,
      statusCode,
    );
  }

  // Alchemy logs out the API key in the error message
  // so we need to strip it out
  public stripRpcApiKeys(err: string): string {
    // receives: "https://eth-goerli.alchemyapi.io/v2/api-keys"
    // returns:  "https://eth-goerli.alchemyapi.io/[redacted]"
    return err.replace(/v2\/.*/, '[redacted]');
  }

  private getFilename(filename: string): string {
    let split;

    if (filename.includes('/')) {
      // Mac & Linux
      split = filename.split('/');
    } else if (filename.includes('\\')) {
      // Windows
      split = filename.split('\\');
    } else {
      return filename;
    }

    return split[split.length - 1];
  }
}
