import { Contract, ethers, Wallet } from 'ethers';
import { TransactionRequest } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import { Logger } from '../../../../../../src/utils/logger';
import erc1155Abi from '../abis/erc1155.json';
import erc165Abi from '../abis/erc165.json';
import erc721Abi from '../abis/erc721.json';
import { ERC1155InterfaceId, ERC721InterfaceId } from '../constants';

const logger = new Logger(__filename);

function getApprovalParams(
  contract: Contract,
  data: string,
): { spender: string; tokenId?: number } | undefined {
  let parsed;
  try {
    parsed = contract.interface.parseTransaction({ data });  
  } catch(e:any) {
    // Trying to call a custom method that is not supported by the ERC721 contract
    // E.g. calling Claim method on a ThirdWeb NFT contract
    if(e.reason === 'no matching function') {
      return undefined
    }
    // Otherwise, resurface the error
    throw e;
  }
  
  if (parsed.name === 'setApprovalForAll') {
    return {
      spender: parsed.args.operator,
    };
  } else if (parsed.name === 'approve') {
    return {
      spender: parsed.args.to,
      tokenId: parsed.args.tokenId.toNumber(),
    };
  }
}

async function getNftApproval(wallet: Wallet, tx: TransactionRequest) {
  const { to, data } = tx;
  if (!to || !data) {
    return;
  }
  const erc165Contract = new ethers.Contract(to, erc165Abi, wallet.provider);
  let isERC1155 = false;
  let isERC721 = false;
  try {
    [isERC1155, isERC721] = await Promise.all([
      erc165Contract.callStatic.supportsInterface(ERC1155InterfaceId),
      erc165Contract.callStatic.supportsInterface(ERC721InterfaceId),
    ]);
  } catch (e) {
    logger.logWarn(
      `getNftApproval`,
      `Failed calling 'supportsInterface' on ${to}`,
      e,
    );
    return;
  }

  if (!isERC1155 && !isERC721) {
    return;
  }

  const abi = isERC1155 ? erc1155Abi : erc721Abi;

  const nftContract = new ethers.Contract(to, abi, wallet.provider);
  const nftName = await nftContract.callStatic.name();
  const approvalParams = getApprovalParams(nftContract, tx.data!);

  if (approvalParams) {
    return {
      name: nftName,
      ...approvalParams,
    };
  }
  // If no approval params were found, return null which skips the approval step in the UI flow
  return false;
}

export { getApprovalParams, getNftApproval };
