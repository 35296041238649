import { WalletNetworkToSymbolMap } from '@moonpay/login-common';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { ErrorManager } from 'src/utils/errorManager';

const errorManager = new ErrorManager(__filename);

export async function chainIdValidate(req: PromptRequest) {
  const networkSymbol = WalletNetworkToSymbolMap[req.network];

  if (req.request.method !== `${networkSymbol}_chainId`) {
    throw errorManager.getServerError(
      'chainIdValidate',
      `Invalid request method`,
    );
  }

  if (req.request.params && req.request.params.length > 0) {
    throw errorManager.getServerError(
      'chainIdValidate',
      `Invalid request params`,
    );
  }
}

export async function chainIdExecute({
  walletStorage,
  network,
}: PromptRequest) {
  return walletStorage.activeChainId.getActiveChainIdByNetwork(network);
}

// empty reject and prompt since this is a no prompt method
export async function chainIdReject() {}
export async function chainIdPrompt() {
  return undefined;
}
