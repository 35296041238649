import { SignatureIcon } from '@moonpay-widget/ui-kit';
import { ethers } from 'ethers';

import i18n, { strings } from 'src/i18n';
import PersonalSignMessage from 'src/messages/walletProxy/methods/personalSign/PersonalSignMessage';
import {
  PromptRequest,
  PromptResponse,
} from 'src/messages/walletProxy/methods/types';
import { validateWalletSigner } from 'src/messages/walletProxy/utils/validateWalletSigner';
import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from 'src/utils/errorManager';
import { EventTrackingUtils } from 'src/utils/eventTracking';
import { Bitcoin, Ethereum, match } from 'src/wallet/types/Wallet';

const errorManager = new ErrorManager(__filename);

export const OPENSEA_ORIGINS = [
  'https://opensea.io',
  'https://testnets.opensea.io',
  'http://localhost:3001',
];

export function isOpenseaOrigin(origin: string) {
  return OPENSEA_ORIGINS.includes(origin);
}

function rawMessageToString(message: string) {
  return ethers.utils.toUtf8String(message);
}

function getAddressMessage(
  params: [string, string],
): [address: string, message: string] {
  const [address, message] = ethers.utils.isAddress(params[0])
    ? [params![0], params[1]]
    : [params![1], params[0]];
  return [address, rawMessageToString(message)];
}

export async function personalSignValidate({
  request,
  abstractWallet,
}: PromptRequest): Promise<void> {
  if (request.method !== 'personal_sign' && request.method !== 'eth_sign') {
    throw errorManager.getServerError(
      'personalSignValidate',
      `Invalid request method`,
    );
  }

  if (!request.params) {
    throw errorManager.getServerError(
      'personalSignValidate',
      `Invalid request params`,
    );
  }
  if (!Array.isArray(request.params)) {
    throw errorManager.getServerError(
      'personalSignValidate',
      `Invalid request params type`,
    );
  }
  if (request.params.length !== 2) {
    throw errorManager.getServerError(
      'personalSignValidate',
      `Invalid request params length`,
    );
  }
  if (typeof request.params[0] !== 'string') {
    throw errorManager.getServerError(
      'personalSignValidate',
      `Invalid request params[0] type`,
    );
  }
  if (typeof request.params[1] !== 'string') {
    throw errorManager.getServerError(
      'personalSignValidate',
      `Invalid request params[1] type`,
    );
  }

  if (!abstractWallet) {
    throw errorManager.getServerError('personalSignValidate', `Invalid wallet`);
  }

  await match(abstractWallet, {
    Bitcoin: async (_btcWallet: Bitcoin) => {
      throw errorManager.getServerError('match', `Unsupported wallet`);
    },
    Ethereum: async (ethWallet: Ethereum) => {
      const [address] = getAddressMessage(request.params as [string, string]);
      validateWalletSigner(ethWallet.wallet, address);
    },
  });
}

export async function personalSignExecute({
  request,
  abstractWallet,
}: PromptRequest) {
  if (!abstractWallet) {
    throw errorManager.getServerError('personalSignExecute', `Invalid wallet`);
  }

  let signedMessage = await match<string>(abstractWallet, {
    Bitcoin: async (_btcWallet: Bitcoin) => {
      throw errorManager.getServerError(
        'personalSignExecute',
        `Unsupported wallet`,
      );
    },
    Ethereum: async (ethWallet: Ethereum) => {
      const [, message] = getAddressMessage(request.params as [string, string]);
      return await ethWallet.wallet.signMessage(message);
    },
  });

  if (signedMessage instanceof Error) {
    throw signedMessage;
  }

  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.signatureRequestApproved,
  );

  return signedMessage;
}

export async function personalSignReject(req: PromptRequest) {
  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.signatureRequestCancelled,
  );
}

export async function personalSignPrompt({
  request,
  origin,
}: PromptRequest): PromptResponse {
  const [, message] = getAddressMessage(request.params as [string, string]);
  const isSpecialOpenseaMessage =
    message.includes('I Accept') && isOpenseaOrigin(origin);

  if (!isSpecialOpenseaMessage) {
    return {
      title: i18n.t(strings.prompts.signatureRequest.title),
      accountLogo: SignatureIcon,
      component: <PersonalSignMessage text={[message]} />,
      approveButtonText: i18n.t(
        strings.prompts.signatureRequest.footer.approvalButtonText,
      ),
      cancelButtonText: i18n.t(
        strings.prompts.signatureRequest.footer.cancelButtonText,
      ),
      aboveButtonsText: i18n.t(strings.prompts.signatureRequest.footer.title),
    };
  }
}
