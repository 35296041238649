import { GetOpenIdTokenForDeveloperIdentityResponse } from '@aws-sdk/client-cognito-identity';
import { Buffer } from 'buffer';
import { ErrorManager } from 'src/utils/errorManager';
import { Logger } from 'src/utils/logger';
import StorageUtils from 'src/utils/storage';

const MOONPAY_API_BASE_URL = process.env
  .REACT_APP_MOONPAY_API_REST_URL as string;

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);

export interface WalletConnectionInput {
  walletAddress: string;
  phoneNumber?: string;
  email?: string;
  piiConsent?: boolean;
}

export interface AccountTermsPrivacyDetails {
  name: string;
  termsLink?: string;
  privacyLink?: string;
}

export interface CustomerAccountConnection {
  connected: boolean;
  piiConsent: boolean;
  tosAccepted: boolean;
  privacyPolicyAccepted: boolean;
}

export interface KmsWalletDetail {
  address: string;
  chain: string;
}

export interface KmsWalletData {
  encryptedWallet: string;
  cipherText: Buffer;
  iv: Buffer;
}

export interface KmsWalletResponse {
  encryptedWallet: string;
  cipherText: string;
  iv: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  kmsWalletDetails: KmsWalletDetail[];
}

export interface CreateKmsWalletTransactionRequest {
  transactionType: KmsWalletTransactionType;
  transactionMetadata: CryptoTransactionMetadata | NftTransactionMetadata;
  networkCode: string;
  transactionHash: string;
  from: string;
  to: string | null;
  transactionFee: string;
  transactionFeeUnits: string;
}

export enum KmsWalletTransactionType {
  crypto = 'crypto',
  nft = 'nft',
}

export interface CryptoTransactionMetadata {
  baseCurrency: any;
  baseCurrencyAmount: string;
}

export interface NftTransactionMetadata {
  contractAddress: string;
  tokenId: number;
  tokenAmount: number;
}

async function fetchApiCustomer(path: string, method = 'GET', body?: any) {
  const csrfToken = StorageUtils.getItem('csrfToken');

  const headers: { 'Content-Type': string; 'X-CSRF-TOKEN'?: string } = {
    'Content-Type': 'application/json',
  };
  if (csrfToken) {
    // Set csrfToken if we have it. Otherwise it will be injected in tokenWorker.js.
    headers['X-CSRF-TOKEN'] = csrfToken;
  }

  const req = { method, headers, credentials: 'include' as const, body };
  return fetch(`${MOONPAY_API_BASE_URL}${path}`, req);
}

async function fetchApiAccount(
  path: string,
  apiKey: string,
  method = 'GET',
  body?: any,
) {
  if (!apiKey) {
    throw errorManager.getServerError(
      'chainfetchApiAccountdValidate',
      `API key is required`,
      {
        path,
        method,
        body,
      },
    );
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Api-Key ${apiKey}`,
  };

  const req = { method, headers, credentials: 'include' as const, body };

  return fetch(`${MOONPAY_API_BASE_URL}${path}`, req);
}

async function fetchApiAccountAndCustomer(
  path: string,
  apiKey: string,
  method = 'GET',
  body?: any,
) {
  const csrfToken = StorageUtils.getItem('csrfToken');

  if (!apiKey) {
    throw errorManager.getServerError(
      'fetchApiAccountAndCustomer',
      `API key is required`,
      {
        path,
        method,
        body,
      },
    );
  }

  const headers: { 'Content-Type': string; 'X-CSRF-TOKEN'?: string } = {
    'Content-Type': 'application/json',
  };

  if (csrfToken) {
    // Set csrfToken if we have it. Otherwise it will be injected in tokenWorker.js.
    headers['X-CSRF-TOKEN'] = csrfToken;
  }

  const queryParams = new URLSearchParams({ apiKey }).toString();
  const req = { method, headers, credentials: 'include' as const, body };
  if (method === 'GET') {
    return fetch(`${MOONPAY_API_BASE_URL}${path}?${queryParams}`, req);
  }
  return fetch(`${MOONPAY_API_BASE_URL}${path}`, req);
}

export const setEncryptedKmsWallet = async (
  cipherText: string,
  iv: string,
  encryptedWallet: string,
) => {
  return fetchApiCustomer(
    '/wallets/v1/user/encrypted',
    'POST',
    JSON.stringify({ cipherText, iv, encryptedWallet }),
  );
};

export const getEncryptedKmsWallet = async (): Promise<
  KmsWalletData | undefined
> => {
  const resp = await fetchApiCustomer('/wallets/v1/user/encrypted');

  // 404 from api means no wallet, else always throw error
  if (resp.status === 404) {
    return undefined;
  } else if (resp.status !== 200) {
    throw errorManager.getServerError(
      'getEncryptedKmsWallet',
      `Failed to get encrypted wallet`,
      {
        response: resp,
        responseStatus: resp.status,
      },
    );
  }

  const result = (await resp.json()) as KmsWalletResponse;
  return {
    encryptedWallet: result.encryptedWallet,
    iv: Buffer.from(result.iv, 'hex'),
    cipherText: Buffer.from(result.cipherText, 'hex'),
  };
};

export const shouldUseKms = async (): Promise<boolean> => {
  const useKms = await fetchApiCustomer('/wallets/v1/user/use-kms');
  return (await useKms.json()) as boolean;
};

export const getIdentity = async () => {
  const resp = await fetchApiCustomer('/wallets/v1/cognito/session');
  return (await resp.json()) as GetOpenIdTokenForDeveloperIdentityResponse;
};

export const addKmsWalletDetails = async (
  address: string,
  chain: string,
): Promise<KmsWalletDetail[]> => {
  const resp = await fetchApiCustomer(
    '/wallets/v1/user/wallet-details',
    'POST',
    JSON.stringify({ address, chain }),
  );

  if (!resp.ok) {
    throw errorManager.getServerError(
      'addKmsWalletDetails',
      `Failed to add kms wallet details`,
      {
        response: resp,
        responseStatus: resp.status,
        address,
        chain,
      },
    );
  }

  const result = (await resp.json()) as KmsWalletResponse;

  return result.kmsWalletDetails;
};

export const getKmsWalletDetails = async (): Promise<KmsWalletDetail[]> => {
  const resp = await fetchApiCustomer('/wallets/v1/user/wallet-details');

  if (!resp.ok) {
    throw errorManager.getServerError(
      'getKmsWalletDetails',
      `Failed to get kms wallet details`,
      {
        response: resp,
        responseStatus: resp.status,
      },
    );
  }

  return (await resp.json()) as KmsWalletDetail[];
};

export const checkCustomerAccountConnection = async (
  apiKey: string,
): Promise<CustomerAccountConnection> => {
  const resp = await fetchApiAccountAndCustomer('/v1/connection', apiKey);

  if (!resp.ok) {
    throw errorManager.getServerError(
      'checkCustomerAccountConnection',
      `Failed to get wallet connection`,
      {
        response: resp,
        responseStatus: resp.status,
      },
    );
  }

  return (await resp.json()) as CustomerAccountConnection;
};

export const getAccountTermsAndPrivacy = async (
  apiKey: string,
): Promise<AccountTermsPrivacyDetails> => {
  const resp = await fetchApiAccountAndCustomer(
    '/v1/connection/account-details',
    apiKey,
  );

  if (!resp.ok) {
    throw errorManager.getServerError(
      'getAccountTermsAndPrivacy',
      `Failed to get account details`,
      {
        response: resp,
        responseStatus: resp.status,
      },
    );
  }

  return (await resp.json()) as AccountTermsPrivacyDetails;
};

export const setWalletConnection = async (
  apiKey: string,
  walletConnection: WalletConnectionInput,
) => {
  const walletConnectionWithApiKey = {
    ...walletConnection,
    apiKey: apiKey,
  };
  return fetchApiAccountAndCustomer(
    '/v1/connection',
    apiKey,
    'POST',
    JSON.stringify(walletConnectionWithApiKey),
  );
};

/*
  We consistently see a large volume of status 0 at this endpoint,
  which indicates a failed preflight request. This could be due to
    - CORS issues
    - Network errors (e.g. DNS resolution)
    - Ad Blockers
*/
export const getAllowedAncestorOrigins = async (
  apiKey: string,
): Promise<string[]> => {
  try {
    const resp = await fetchApiAccount(
      '/wallets/v1/user/allowed-ancestors',
      apiKey,
    );

    if (!resp.ok) {
      throw errorManager.getServerError(
        'getAllowedAncestorOrigins',
        `Failed to get allowed ancestor origins`,
        { response: resp, responseStatus: resp.status, apiKey },
      );
    }

    const result = (await resp.json()) as string;

    return result.split(',').map((origin) => origin.trim());
  } catch (error) {
    throw errorManager.getClientError(
      'getAllowedAncestorOrigins',
      `Failed to get allowed ancestor origins`,
      { error },
    );
  }
};

export const createKmsWalletTransaction = async (
  data: CreateKmsWalletTransactionRequest,
): Promise<void> => {
  try {
    const resp = await fetchApiCustomer(
      '/wallets/v1/kms-wallet-transactions',
      'POST',
      JSON.stringify(data),
    );

    if (!resp.ok) {
      const responseBody = await resp.text();
      console.error(
        `Failed to create kms wallet transaction. Status: ${resp.status}, Response: ${responseBody}`,
      );
    }
  } catch (error) {
    console.error(`Unexpected error in create transaction:`, error);
  }
};
