import {
  switchNetworkExecute,
  switchNetworkPrompt,
  switchNetworkValidate,
} from './switchNetwork';
import { MethodImplementation } from 'src/messages/walletProxy/methods/types';

const switchNetwork: MethodImplementation = {
  validate: switchNetworkValidate,
  prompt: switchNetworkPrompt,
  execute: switchNetworkExecute,
};

export { switchNetwork };
