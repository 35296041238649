import { SummaryRow, Text, UpdatedSummaryTable } from '@moonpay-widget/ui-kit';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const BoldName = styled.span`
  display: inline-block;
  max-width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.25;
  vertical-align: middle;
  text-weight: 500;
`;

export interface SignTypedDataProps {
  validTypes: {
    [key: string]: { name: string; type: string }[];
  };
  domain: any;
  message: any;
}

const treatValue = (value: any) => {
  if (typeof value === 'string') {
    if (value.length > 20) {
      // show full value on hover
      return <span title={value}>{`${value.slice(0, 20)}...`}</span>;
    }

    return <span>{value}</span>;
  }
  return <pre>{JSON.stringify(value, null, 2)}</pre>;
};

const SignTypedDataPrompt: FunctionComponent<SignTypedDataProps> = ({
  validTypes,
  domain,
  message,
}) => {
  const buildMessageRows = () => {
    return Object.keys(message).map((key: any) => {
      const value = message[key];

      const fullRender = [];

      if (typeof value === 'object') {
        // push header
        fullRender.push(
          <div className="mt-4 text-left w-full">
            <Text variant="headline" className="capitalize">
              {key}
            </Text>
          </div>,
        );

        for (let item of value) {
          for (let innerKey of Object.keys(item)) {
            fullRender.push(
              <SummaryRow
                title={innerKey}
                value={treatValue(item[innerKey])}
                titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
              />,
            );
          }
        }
      } else if (typeof value === 'string') {
        fullRender.push(
          <SummaryRow
            title={key}
            value={treatValue(value)}
            titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
            valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
          />,
        );
      }

      return fullRender;
    });
  };

  return (
    <div>
      <UpdatedSummaryTable
        className="mt-2"
        header={
          <div className="flex items-center">
            <Text variant="callout">Message</Text>
          </div>
        }
        openByDefault={true}
      >
        <div className="mt-2 bg-system-background-secondary rounded-xl">
          {buildMessageRows()}
        </div>
      </UpdatedSummaryTable>

      <UpdatedSummaryTable
        className="mt-2"
        header={
          <div className="flex items-center">
            <Text variant="callout">Types</Text>
          </div>
        }
      >
        {Object.keys(validTypes).map((objectKey) => {
          return (
            <>
              <BoldName>{objectKey}</BoldName>
              <div className="px-2 bg-system-background-secondary rounded-xl">
                {validTypes[objectKey].map(
                  (textPart: { name: string; type: string }) => {
                    return (
                      <SummaryRow
                        title={textPart.name}
                        value={textPart.type}
                        titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                        valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                      />
                    );
                  },
                )}
              </div>
            </>
          );
        })}
      </UpdatedSummaryTable>

      <UpdatedSummaryTable
        className="mt-2"
        header={
          <div className="flex items-center">
            <Text variant="subhead">Domain</Text>
          </div>
        }
      >
        <div className="px-4 mb-8 mt-2 bg-system-background-secondary rounded-xl">
          {Object.keys(domain).map((key: string) => {
            return (
              <SummaryRow
                title={key}
                value={treatValue(domain[key])}
                titleClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
                valueClassName="text-ellipsis overflow-hidden whitespace-nowrap max-w-1/3 mt-2"
              />
            );
          })}
        </div>
      </UpdatedSummaryTable>
    </div>
  );
};

export default SignTypedDataPrompt;
