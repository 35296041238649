import { WalletNetwork } from '@moonpay/login-common';
import { ErrorManager } from 'src/utils/errorManager';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';

const errorManager = new ErrorManager(__filename);

export function switchActiveChainId(
  walletStorage: WalletStorage,
  network: WalletNetwork,
  isMainnet: boolean,
) {
  if (isMainnet) {
    switch (network) {
      case WalletNetwork.Ethereum:
        walletStorage.activeChainId.setActiveChainIdByNetwork(network, 1);
        break;
      case WalletNetwork.Bitcoin:
        walletStorage.activeChainId.setActiveChainIdByNetwork(network, 0);
        break;
      default:
        throw errorManager.getServerError(
          'switchActiveChainId',
          `Invalid network`,
        );
    }
  } else {
    switch (network) {
      case WalletNetwork.Ethereum:
        walletStorage.activeChainId.setActiveChainIdByNetwork(network, 5);
        break;
      case WalletNetwork.Bitcoin:
        walletStorage.activeChainId.setActiveChainIdByNetwork(network, 1);
        break;
      default:
        throw errorManager.getServerError(
          'switchActiveChainId',
          `Invalid network`,
        );
    }
  }
}
