/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { sendMessageToParent } from 'src/messages/Message';
import { ColorSchemeVariant } from 'src/wallet/types/ColorSchemeVariant';
import './SeedPhraseVerification.css';
import {
  SeedPhraseIndex,
  SeedPhraseInput,
  SeedPhraseVerificationContainer,
} from './SeedPhraseVerification.styles';
import useForm from './useForm';
import useRandomSeedPhraseSample from './useRandomSeedPhraseSample';

const NUMBER_OF_WORDS_TO_BE_VERIFIED = 6;

type SeedPhraseVerificationForm = Record<string, string>;

interface SeedPhraseVerificationProps {
  seedPhrase: string;
}

function SeedPhraseVerification({ seedPhrase }: SeedPhraseVerificationProps) {
  const seedPhraseArray = seedPhrase?.split(' ') ?? [];

  const { possibleIndexes, sampleIndexes } = useRandomSeedPhraseSample(
    seedPhrase,
    NUMBER_OF_WORDS_TO_BE_VERIFIED,
  );

  const { searchParams } = new URL(window.location.href);
  const theme: string = searchParams.get('theme') ?? 'light';

  const { formValues, errors, handleChangeText } =
    useForm<SeedPhraseVerificationForm>({
      initialValues: {},
      validate: (values: SeedPhraseVerificationForm) => {
        return sampleIndexes.reduce((validationResult, index) => {
          const value = values[index];
          const expectedValue = seedPhraseArray[index];
          if (value !== expectedValue) {
            validationResult = {
              ...(validationResult ?? {}),
              [index]: 'Incorrect phrase',
            };
          }

          return validationResult;
        }, null as unknown as Record<string, string>);
      },
    });

  const hasErrors = Boolean(errors);
  useEffect(() => {
    if (!hasErrors) {
      sendMessageToParent('seed-phrase-verify-success', []);
    } else {
      sendMessageToParent('seed-phrase-verify-error', []);
    }
  }, [hasErrors]);

  return (
    <SeedPhraseVerificationContainer
      data-testid="SeedPhraseVerification"
      className="seed-phrase-verification-container"
      isDarkMode={theme === ColorSchemeVariant.Dark}
    >
      {possibleIndexes.map((_, index) => {
        return sampleIndexes.includes(index) ? (
          <div
            key={`phrase-placeholder-${index}`}
            className="seed-phrase-input-container"
          >
            <SeedPhraseIndex
              className="seed-phrase-index"
              isDarkMode={theme === ColorSchemeVariant.Dark}
            >
              {index + 1}
            </SeedPhraseIndex>
            <SeedPhraseInput
              autoCapitalize="off"
              autoComplete="off"
              className="seed-phrase-input"
              data-index={index}
              data-testid={`seed-phrase-input-${index}`}
              name={`${index}`}
              onChange={handleChangeText}
              value={formValues[index] ?? ''}
              isDarkMode={theme === ColorSchemeVariant.Dark}
            />
          </div>
        ) : (
          <span key={`phrase-placeholder-${index}`} />
        );
      })}
    </SeedPhraseVerificationContainer>
  );
}

export default SeedPhraseVerification;
