import { FC } from 'react';
import {
  mapBackupTypeToBackupMethod,
  mapBackupTypeToProvider,
  WalletBackupType,
} from '../../../messages/Message';
import { FinalizeFlowParams } from '../../../moonpayApi/finalizeDhwFlow';
import useCsrfToken from '../../../moonpayApi/hooks/useCsrfToken';
import { Logger } from '../../../utils/logger';
import { AbstractWallet } from '../../types/Wallet';
import { BackupWalletComponentProps } from './BackupWalletComponent.props';

const logger = new Logger(__filename);

export interface Props {
  wallet: AbstractWallet;
  onWalletBackedUpSuccess: (params: FinalizeFlowParams) => Promise<void>;
  BackupComponent: FC<BackupWalletComponentProps>;
  backupType: WalletBackupType;
}

const BackupWalletFlow: FC<Props> = ({
  BackupComponent,
  wallet,
  backupType,
  onWalletBackedUpSuccess,
}: Props) => {
  logger.logInfo('BackupWalletFlow', 'Rendering <BackupWalletFlow />');
  const csrfToken = useCsrfToken();

  const handleWalletBackedUp = () => {
    onWalletBackedUpSuccess({
      csrfToken,
      wallet,
      provider: mapBackupTypeToProvider(backupType),
      backupMethod: mapBackupTypeToBackupMethod(backupType),
    });
  };

  return (
    <BackupComponent wallet={wallet} onWalletBackedUp={handleWalletBackedUp} />
  );
};

export default BackupWalletFlow;
