import {
  EthProviderRpcError,
  WalletMessage,
  WalletMessageResponse,
  WalletMessageType,
  WalletNetwork,
} from '@moonpay/login-common';
import getTargetOrigin from './getTargetOrigin';

type RequestReplyMessage =
  | 'get-wallet'
  | 'create-wallet'
  | 'restore-wallet'
  | 'backup-wallet';

type FireAndForgetOutboundMessage = 'ready';

type ProxyMessage = 'proxy';

type MessageResult = 'success' | 'error';

type OneWayOutboundMessage = 'seed-phrase-verify';

type MessageType =
  | FireAndForgetOutboundMessage
  | `${RequestReplyMessage}-${MessageResult}`
  | `${ProxyMessage}-${RequestReplyMessage}-${MessageResult}`
  | `${OneWayOutboundMessage}-${MessageResult}`;

export type WalletBackupType = 'cloud' | 'manual';
export const mapBackupTypeToProvider = (backupType: WalletBackupType) =>
  backupType === 'cloud' ? 'gdrive' : 'manual';
export const mapBackupTypeToBackupMethod = (backupType: WalletBackupType) =>
  backupType === 'cloud' ? 'Google' : 'Manual';

export enum WalletType {
  Custodial = 'custodial',
  NonCustodial = 'non-custodial',
}

export interface WalletSignMessageRequestPayload {
  walletType: string;
  type: 'proxy-sign-message';
  walletEncryptionKeyExists: boolean;
  unsignedMessage: any;

  // custodial purposes
  rpcMethod?: string;
  rpcParams?: any[];
}

export interface WalletSignTransactionRequestPayload {
  walletType: string;
  type: 'proxy-sign-transaction';
  walletEncryptionKeyExists: boolean;
  unsignedTransaction: any;

  // custodial purposes
  rpcMethod?: string;
  rpcParams?: any[];
}

export interface WalletBackupRequestPayload {
  type: 'backup-wallet';
  backupType: WalletBackupType;
}

export interface Message<PayloadType = null> extends MessageEvent {
  type: MessageType;
  payload: PayloadType;
}

export type OnPromptChangeFunc = (
  action: string,
  network: WalletNetwork,
) => void;

const createMessage: <T = Record<string, unknown>>(
  type: MessageType,
  payload?: T,
) => Message = (type, payload) => {
  return {
    type,
    ...payload,
  } as Message;
};

const sendMessageToParent: <T = Record<string, unknown>>(
  type: MessageType,
  origins: string[],
  payload?: T,
) => void = (type, origins, payload) => {
  const message = createMessage(type, payload);
  window.parent.postMessage(
    JSON.parse(JSON.stringify(message)),
    getTargetOrigin(origins),
  );
};

function sendWalletMessage(message: WalletMessage, origins: string[]): void {
  window.parent.postMessage(
    JSON.parse(JSON.stringify(message)),
    getTargetOrigin(origins),
  );
}

function sendWalletReply(
  id: string,
  payload: { result?: any; error?: EthProviderRpcError },
  origins: string[],
): void {
  const message: WalletMessageResponse = {
    type: WalletMessageType.MESSAGE_PROXY_RESPONSE,
    id,
    ...payload,
  };
  sendWalletMessage(message, origins);
}

export { sendMessageToParent, sendWalletMessage, sendWalletReply };
