import { CurrencyXCurrency } from '@moonpay-widget/ui-kit';
import BitcoinIcon from '@moonpay-widget/ui-kit/src/CurrencyIcons/BitcoinIcon';
import EthereumIcon from '@moonpay-widget/ui-kit/src/CurrencyIcons/EthereumIcon';
import PolygonIcon from '@moonpay-widget/ui-kit/src/CurrencyIcons/PolygonIcon';
import UnknownIcon from '@moonpay-widget/ui-kit/src/CurrencyIcons/UnknownIcon';
import { WalletNetwork } from '@moonpay/login-common';
import { FunctionComponent } from 'react';
import { EthereumChainSpec } from 'src/wallet/storage/ChainsStorage';

export interface SwitchNetworkPromptProps {
  oldChain: EthereumChainSpec;
  newChain: EthereumChainSpec;
  isNotAllowed: boolean;
  network: WalletNetwork;
}

const chainNameToIcon: Record<
  WalletNetwork,
  Record<string, FunctionComponent>
> = {
  [WalletNetwork.Ethereum]: {
    '0x1': EthereumIcon,
    '0x5': EthereumIcon,
    '0x89': PolygonIcon,
    '0x13881': PolygonIcon,
  },
  [WalletNetwork.Bitcoin]: {
    '0x0': BitcoinIcon,
    '0x1': BitcoinIcon,
  },
};

export const getChainIcon = (network: WalletNetwork, chainId?: string) => {
  if (!chainId) {
    return UnknownIcon;
  }

  return chainNameToIcon[network][chainId] || UnknownIcon;
};

const SwitchNetworkPrompt: FunctionComponent<SwitchNetworkPromptProps> = ({
  oldChain,
  newChain,
  isNotAllowed,
  network,
}) => {
  return (
    <div className="flex dialog-actions flex-row justify-center py-16">
      <CurrencyXCurrency
        currencyFrom={{
          name: oldChain.chainName || `Chain ID: ${oldChain.chainId}`,
          icon: getChainIcon(network, oldChain.chainId),
        }}
        currencyTo={{
          name: newChain.chainName || `Chain ID: ${newChain.chainId}`,
          icon: getChainIcon(network, newChain.chainId),
        }}
        isNotAllowed={isNotAllowed}
      />
    </div>
  );
};

export default SwitchNetworkPrompt;
