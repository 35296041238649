import {
  ChangeNetworkRequestIcon,
  NetworkNotSupportedIcon,
} from '@moonpay-widget/ui-kit/src/icons';

import { WalletNetworkToSymbolMap } from '@moonpay/login-common';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from 'src/utils/errorManager';
import { EventTrackingUtils } from 'src/utils/eventTracking';
import { EthereumChainSpec } from 'src/wallet/storage/ChainsStorage';
import SwitchChainPrompt, {
  SwitchNetworkPromptProps,
} from './SwitchNetworkPrompt';

const errorManager = new ErrorManager(__filename);

export async function switchNetworkValidate({
  request,
  network,
}: PromptRequest): Promise<void> {
  const networkSymbol = WalletNetworkToSymbolMap[network];

  if (
    request.method !== `${networkSymbol}_switchNetwork` &&
    request.method !== 'wallet_switchEthereumChain'
  ) {
    throw errorManager.getServerError(
      'switchNetworkValidate',
      `Invalid request method`,
    );
  }

  const params = request.params;

  if (!Array.isArray(params) || params.length !== 1) {
    throw errorManager.getServerError(
      'switchNetworkValidate',
      `Invalid request params`,
    );
  }

  if (typeof params[0] !== 'object') {
    throw errorManager.getServerError(
      'switchNetworkValidate',
      `Invalid request params[0] is not an object`,
    );
  }

  if (typeof params[0].chainId !== 'string') {
    throw errorManager.getServerError(
      'switchNetworkValidate',
      `Invalid request params[0].chainId type`,
    );
  }
}

export async function switchNetworkExecute({
  request,
  walletStorage,
  network,
}: PromptRequest): Promise<void> {
  const newChainId = Number(request.params![0].chainId);

  walletStorage.activeChainId.setActiveChainIdByNetwork(network, newChainId);

  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.switchNetworkCompleted,
  );
}

export async function switchNetworkReject(req: PromptRequest): Promise<void> {
  EventTrackingUtils.trackSegmentEvent(
    SegmentTrackEvent.switchNetworkCancelled,
  );
}

export async function switchNetworkPrompt({
  request,
  walletStorage,
  network,
}: PromptRequest) {
  const chainId = Number(request.params![0].chainId);

  const activeChainId =
    walletStorage.activeChainId.getActiveChainIdByNetwork(network);

  // get chain data from storage
  const oldChain = walletStorage.chains.getChain(
    activeChainId.toString(),
    network,
  );
  if (!oldChain) {
    throw errorManager.getServerError('switchNetworkPrompt', `Invalid chainId`);
  }
  let newChain = walletStorage.chains.getChain(chainId.toString(), network);

  // if chain data is not in storage we do not support but still show
  const isNotAllowed = newChain === undefined;

  if (!newChain) {
    newChain = {
      chainName: `Chain ID: ${chainId}`,
      rpcUrls: [] as string[],
      nativeCurrency: {
        name: 'Unknown',
        symbol: 'unknown',
        decimals: 18,
      },
      blockExplorerUrls: [] as string[],
    } as EthereumChainSpec;
  }

  const props: SwitchNetworkPromptProps = {
    oldChain,
    newChain,
    isNotAllowed,
    network,
  };

  return {
    partnerLogo: isNotAllowed
      ? NetworkNotSupportedIcon
      : ChangeNetworkRequestIcon,
    title: isNotAllowed
      ? 'Network not supported'
      : 'Allow this site to switch the network?',
    message: isNotAllowed
      ? 'The network you are attempting to switch to is not currently supported by MoonPay.'
      : 'This will switch the selected network within MoonPay to a previously added network',
    approvalDisabled: isNotAllowed,
    component: <SwitchChainPrompt {...props} />,
    aboveButtonsText:
      'Switching networks will cancel all pending confirmations',
  };
}
