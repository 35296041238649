import { WalletGetRequestPayload } from '@moonpay-widget/dhw/src/types/messages';
import {
  WalletDetail,
  WalletDetails,
  WalletNetwork,
} from '@moonpay/login-common';
import { switchActiveChainId } from 'src/utils/activeChain';
import StorageUtils from 'src/utils/storage';
import { WalletService } from 'src/wallet/services/walletService';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { Logger } from '../../utils/logger';
import addGetWalletMessageEventListener from './addGetWalletMessageEventListener';

const logger = new Logger(__filename);

export type RegisterGetWalletMessageHandlerParams = {
  walletStorage: WalletStorage;
  onSuccess: (wallets: WalletDetails) => void; // Change the signature to return a list
  onError: (error: any) => void;
  origins: string[];
};

export type HandleGetWalletMessageParams = WalletGetRequestPayload &
  RegisterGetWalletMessageHandlerParams;

export const getWalletMessageHandler = async ({
  onSuccess,
  onError,
  csrfToken: csrfTokenPayload,
  customerToken: customerTokenPayload,
  networks = [WalletNetwork.Ethereum, WalletNetwork.Bitcoin],
  walletStorage,
  isMainnet = true,
}: HandleGetWalletMessageParams) => {
  const wallets: WalletDetail[] = [];

  // Store csrf and customer tokens to the service worker/cookies, if necessary
  if (csrfTokenPayload) StorageUtils.setCsrfToken(csrfTokenPayload);
  if (customerTokenPayload) StorageUtils.setCustomerToken(customerTokenPayload);

  for (const network of networks) {
    try {
      switchActiveChainId(walletStorage, network, isMainnet);

      const wallet = await WalletService.restoreWallet(network, walletStorage);
      if (wallet?.address) {
        wallets.push({ address: wallet.address, network });
      }
    } catch (e) {
      logger.logError(
        'getWalletMessageHandler',
        'Error occurred whilst registering the getWalletMessageHandler',
        { error: e },
      );
      console.error(e);
      onError(e);
    }
  }

  onSuccess({ wallets });
  return;
};

/* istanbul ignore next */
// REASON: 
// This code is a proxy for other code and should be tested from there or moved. 
const registerGetWalletMessageHandler = (
  params: RegisterGetWalletMessageHandlerParams,
) => {
  addGetWalletMessageEventListener(
    (payload: WalletGetRequestPayload) =>
      getWalletMessageHandler({
        ...params,
        ...payload,
      }),
    params.origins,
  );
};

export default registerGetWalletMessageHandler;
