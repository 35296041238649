import { WalletNetwork, WalletNetworkToSymbolMap } from '@moonpay/login-common';
import axios from 'axios';
import { PromptRequest } from 'src/messages/walletProxy/methods/types';
import { ErrorManager } from 'src/utils/errorManager';
import { Bitcoin, Ethereum, match } from 'src/wallet/types/Wallet';

const errorManager = new ErrorManager(__filename);
const cryptoApiBaseUrl = process.env.REACT_APP_CRYPTO_API_URL || '';

export async function getBalanceValidate(req: PromptRequest) {
  const networkSymbol = WalletNetworkToSymbolMap[req.network];

  if (req.request.method !== `${networkSymbol}_getBalance`) {
    throw errorManager.getServerError(
      'getBalanceValidate',
      `Invalid request method`,
    );
  }

  if (req.request.params && req.request.params.length > 0) {
    throw errorManager.getServerError(
      'getBalanceValidate',
      `Invalid request params`,
    );
  }
}

interface coinsResponse {
  addressStatus: string;
  page: {
    elements: {
      address: string;
      chain: WalletNetwork;
      network: string;
      value: number;
    }[];
  };
}

export async function getBalanceExecute({
  network,
  abstractWallet,
  walletStorage,
}: PromptRequest) {
  const balance = match<number>(abstractWallet, {
    Bitcoin: async (_btcWallet: Bitcoin) => {
      // TODO: get the network from the walletStorage and use test3 for testnet
      const url = new URL(`${cryptoApiBaseUrl}/api/v3/coins`);
      url.searchParams.append('address', abstractWallet.address);
      url.searchParams.append(
        'network',
        walletStorage.activeChainId.getActiveChainIdByNetwork(network) === 0
          ? 'mainnet'
          : 'testnet',
      );
      url.searchParams.append('chain', network);

      const btcBalance = await axios.get<coinsResponse>(url.toString());

      if (btcBalance.data.addressStatus !== 'supported') {
        throw errorManager.getServerError(
          'getBalanceExecute',
          `Invalid address`,
        );
      }
      if (btcBalance.data.page.elements.length === 0) {
        throw errorManager.getServerError('getBalanceExecute', `No balance`);
      }

      const balance = btcBalance.data.page.elements[0].value * 100000000;
      return balance;
    },
    Ethereum: async (_ethWallet: Ethereum) =>
      Promise.reject(new Error('Not implemented')),
  });

  // if address is an error, throw
  if (balance instanceof Error) {
    throw errorManager.getServerError('getBalanceExecute', `Invalid network`);
  }

  return balance;
}

// empty reject and prompt since this is a no prompt method
export async function getBalanceReject() {}
export async function getBalancePrompt() {
  return undefined;
}
