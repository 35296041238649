import { ErrorManager } from 'src/utils/errorManager';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';

const errorManager = new ErrorManager(__filename);

const useCsrfToken = () => {
  const csrfToken = WalletHelpers.getCsrfToken();
  if (!csrfToken) {
    throw errorManager.getServerError(
      'useCsrfToken',
      'Cannot find user session. Need to re-trigger login.',
    );
  }
  return csrfToken;
};

export default useCsrfToken;
