import { WalletNetwork } from '@moonpay/login-common';
import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';
import { ErrorManager } from 'src/utils/errorManager';
import { Logger } from 'src/utils/logger';
import { WalletService } from 'src/wallet/services/walletService';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { AbstractWallet } from 'src/wallet/types/Wallet';
import { WalletProvider } from 'src/wallet/walletProvider/WalletProvider';
import {
  decryptCipherText,
  getCredentials,
  getDataKey,
} from 'src/wallet/walletProvider/kms/aws';
import encryption from 'src/wallet/walletProvider/kms/encryption';
import {
  KmsWalletData,
  addKmsWalletDetails,
  getEncryptedKmsWallet,
  getIdentity,
  setEncryptedKmsWallet,
} from 'src/wallet/walletProvider/kms/kmsApi';

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);
const IV_LENGTH = 16;

class KmsWalletProvider implements WalletProvider {
  // TODO: make this better, but for now we don't store bitcoin testnet addresses
  static shouldSaveDetails(network: WalletNetwork, chainId: number) {
    return !(network === WalletNetwork.Bitcoin && chainId === 1);
  }

  async create(network: WalletNetwork, walletStorage: WalletStorage) {
    const createWalletFactory = WalletService.cryptoWalletFactory(network);
    const activeChainId =
      walletStorage.activeChainId.getActiveChainIdByNetwork(network);
    const { data: wallet } = createWalletFactory.create(activeChainId);
    if (!wallet) {
      throw errorManager.getServerError('create', `Error creating wallet`, {
        network,
        activeChainId,
      });
    }

    const identity = await getIdentity();
    const { subject, session } = await getCredentials(identity);
    const dataKey = await getDataKey(subject, session);

    const randomIV = CryptoJS.lib.WordArray.random(IV_LENGTH);
    const ivBuffer = Buffer.from(randomIV.toString(), 'hex');
    const encryptionKey = dataKey.plainText;
    const encryptedWallet = await encryption.encrypt({
      payload: wallet.mnemonic.phrase,
      key: encryptionKey,
      iv: ivBuffer,
    });

    const cipherText = Buffer.from(dataKey.cipherText).toString('hex');
    const iv = ivBuffer.toString('hex');

    await setEncryptedKmsWallet(
      cipherText,
      iv,
      encryptedWallet.toString('hex'),
    );

    const walletToStore = JSON.stringify({
      encryptedWallet: encryptedWallet.toString('hex'),
      cipherText,
      iv,
    });

    if (KmsWalletProvider.shouldSaveDetails(network, activeChainId)) {
      await addKmsWalletDetails(wallet.address, wallet.network);
    }

    return {
      walletAddress: wallet.address,
      walletToStore,
    };
  }

  getStoredWallet(walletStorage: WalletStorage) {
    const storedWallet = walletStorage.encryptedWallet.value;

    if (storedWallet) {
      const parsed = JSON.parse(storedWallet);
      return {
        encryptedWallet: parsed.encryptedWallet,
        iv: Buffer.from(parsed.iv, 'hex'),
        cipherText: Buffer.from(parsed.cipherText, 'hex'),
      } as KmsWalletData;
    }
  }

  setStoredWallet(walletStorage: WalletStorage, data: KmsWalletData) {
    const walletToStore = JSON.stringify({
      encryptedWallet: data.encryptedWallet,
      cipherText: data.cipherText.toString('hex'),
      iv: data.iv.toString('hex'),
    });
    walletStorage.encryptedWallet.set(walletToStore);
  }

  async restore(
    network: WalletNetwork,
    walletStorage: WalletStorage,
  ): Promise<AbstractWallet | undefined> {
    let encryptedKmsWallet: KmsWalletData | undefined =
      this.getStoredWallet(walletStorage);
    if (!encryptedKmsWallet) {
      encryptedKmsWallet = await getEncryptedKmsWallet();
    }

    // if there is no wallet even after all of that, they truly don't have a wallet
    if (!encryptedKmsWallet) {
      return undefined;
    }

    const { cipherText, iv, encryptedWallet } = encryptedKmsWallet;

    const identity = await getIdentity();
    const { subject, session } = await getCredentials(identity);
    // This key can be used to decrypt the users wallet
    const encryptionKey = await decryptCipherText(subject, session, cipherText);

    const decryptedMnemonic = await encryption.decrypt({
      encryptedPayload: encryptedWallet,
      key: encryptionKey,
      iv,
    });

    const mnemonic = decryptedMnemonic.toString();

    const activeChainId = walletStorage.activeChainId.value[network];

    const createWalletFactory = WalletService.cryptoWalletFactory(network);
    const { data: wallet } = await createWalletFactory.createFromMnemonic(
      mnemonic,
      activeChainId,
    );

    if (!wallet) {
      throw errorManager.getServerError(
        'restore',
        `KMS: Wallet restore from mnemonic failed`,
        {
          network,
          activeChainId,
          walletStorage,
        },
      );
    }

    this.setStoredWallet(walletStorage, encryptedKmsWallet);

    if (KmsWalletProvider.shouldSaveDetails(network, activeChainId)) {
      await addKmsWalletDetails(wallet.address, wallet.network);
    }

    return wallet;
  }
}

export { KmsWalletProvider };
