import { useEffect, useState } from 'react';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import useGetEncryptionKey from '../../moonpayApi/hooks/useGetEncryptionKey';
import { AbstractWallet } from '../types/Wallet';

interface UseDecryptedWalletProps {
  walletStorage: WalletStorage;
}

const useDecryptedWallet = ({ walletStorage }: UseDecryptedWalletProps) => {
  const [wallet, setWallet] = useState<AbstractWallet>();

  const encryptionKey = useGetEncryptionKey();

  useEffect(() => {
    if (!encryptionKey) {
      return;
    }
    const encryptedWallet = walletStorage.encryptedWallet.value;
    const hydratedWallet = WalletHelpers.decryptWallet(
      encryptedWallet,
      encryptionKey,
    );
    setWallet(hydratedWallet);
  }, [walletStorage, encryptionKey]);

  return wallet;
};

export default useDecryptedWallet;
