import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { Button, DuplicateIcon, Text } from '@moonpay-widget/ui-kit';
import { FunctionComponent, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

export type ExportMnemonicProps = {
  mnemonic: string;
};

const ExportMnemonic: FunctionComponent<ExportMnemonicProps> = ({
  mnemonic,
}) => {
  const [blur, setBlur] = useState(true);
  const [copyText, setCopyText] = useState('Copy');
  const mnemonicWords = mnemonic.split(' ');

  const handleCopyText = () => {
    setCopyText('Copied!');
    setTimeout(() => {
      setCopyText('Copy');
    }, 2000);
  };

  return (
    <>
      <Text variant="subhead" className="text-center text-label-secondary my-6">
        Back up in the correct order and store it in a secure place that only
        you can access.
      </Text>

      <div className="grid grid-cols-2 gap-y-2 gap-x-4 md:grid-cols-4 mb-8 p-4 rounded-xxl bg-system-background-secondary">
        {mnemonicWords.map((word, index) => (
          <div
            key={`${word}-${index}`}
            className="flex justify-left rounded-lg text-label-primary font-medium p-0"
            style={{
              filter: blur ? 'blur(5px)' : 'blur(0px)',
            }}
          >
            <Text
              variant="subhead"
              as="p"
              className="mr-2 text-label-secondary"
            >
              {index + 1}
            </Text>
            <Text variant="subhead" as="p">
              {word}
            </Text>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center gap-4">
        <Button
          onClick={() => setBlur(!blur)}
          variant="custom"
          className="!w-28 bg-system-background-secondary semibold !mt-0"
        >
          {blur ? (
            <EyeIcon className="mr-1 h-5 w-5 text-label-secondary" />
          ) : (
            <EyeOffIcon className="mr-1 h-5 w-5 text-label-secondary" />
          )}
          <Text variant="headline">{blur ? 'Reveal' : 'Hide'}</Text>
        </Button>
        <CopyToClipboard text={mnemonic}>
          <Button
            variant="custom"
            className="!w-28 bg-system-background-secondary semibold !mt-0"
            onClick={handleCopyText}
          >
            <DuplicateIcon size={17} className="mr-1 text-label-secondary" />
            <Text variant="headline">{copyText}</Text>
          </Button>
        </CopyToClipboard>
      </div>
    </>
  );
};

export default ExportMnemonic;
