import { useEffect, useState } from 'react';
import { GetEncryptionKeyResponse } from '../../wallet/types/apiResponses';
import getEncryptionKey from '../getEncryptionKey';
import useCsrfToken from './useCsrfToken';

export type UseGetEncryptionKeyResult =
  | GetEncryptionKeyResponse['walletEncryptionKey']
  | undefined;

const useGetEncryptionKey: () => UseGetEncryptionKeyResult = () => {
  const csrfToken = useCsrfToken();
  const [encryptionKey, setEncryptionKey] =
    useState<GetEncryptionKeyResponse['walletEncryptionKey']>();

  useEffect(() => {
    const getKey = async () => {
      const queriedKey = await getEncryptionKey(csrfToken);
      setEncryptionKey(queriedKey);
    };

    getKey();
  }, [csrfToken]);

  return encryptionKey;
};

export default useGetEncryptionKey;
