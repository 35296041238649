import ecc from '@bitcoinerlab/secp256k1';
import { WalletNetwork } from '@moonpay/login-common';
import BIP32Factory, { BIP32Interface } from 'bip32';
import * as bip39 from 'bip39';
import * as bitcoin from 'bitcoinjs-lib';
import { ethers } from 'ethers';
import { deriveWallets } from 'src/messages/walletProxy/methods/sendTransaction/bitcoin/utils';
import { Result } from 'src/types/Result';
import { ErrorManager } from 'src/utils/errorManager';
import { Logger } from 'src/utils/logger';
import { AbstractWallet, CryptoWallet } from 'src/wallet/types/Wallet';
import { getKmsWalletDetails } from 'src/wallet/walletProvider/kms/kmsApi';

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);

export const DEFAULT_PATH = "m/44'/60'/0'/0/0";
export const DEFAULT_LOCALE = 'en';

export class BitcoinWallet implements CryptoWallet {
  // eslint-disable-next-line class-methods-use-this
  create(chainId: number): Result<AbstractWallet> {
    try {
      const { mnemonic } = ethers.Wallet.createRandom({
        path: DEFAULT_PATH,
        locale: DEFAULT_LOCALE,
      });

      const seed = bip39.mnemonicToSeedSync(mnemonic.phrase);
      const bip32 = BIP32Factory(ecc);
      const btcWallet = bip32.fromSeed(seed).derivePath("m/84'/0'/0'/0/0");
      const privateKeyWIF = btcWallet.toWIF();

      const publicKey = btcWallet.publicKey.toString('hex');

      const { address } = bitcoin.payments.p2wpkh({
        pubkey: btcWallet.publicKey,
        network:
          chainId === 0 ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
      });

      if (!address) {
        throw errorManager.getServerError(
          'create',
          'Bitcoin address not found during creation',
          {
            address,
            chainId,
            publicKey,
          },
        );
      }

      const wallet: AbstractWallet = {
        address,
        network: WalletNetwork.Bitcoin,
        mnemonic,
        privateKey: privateKeyWIF,
        publicKey,
        //
        type: WalletNetwork.Bitcoin,
        wallet: btcWallet,
      };
      return {
        data: wallet,
      };
    } catch (error) {
      logger.logError(
        'create',
        'Error occurred during Bitcoin wallet creation',
        { error, chainId },
      );
      return {
        data: undefined,
        error: error as Error,
      };
    }
  }

  async createFromMnemonic(
    _mnemonic: string,
    chainId: number,
  ): Promise<Result<AbstractWallet>> {
    try {
      const seed = bip39.mnemonicToSeedSync(_mnemonic);
      const bip32 = BIP32Factory(ecc);
      let btcWallet: BIP32Interface;

      // get wallet details
      const kmsWalletDetailsArray = await getKmsWalletDetails();
      const bitcoinWallets = kmsWalletDetailsArray.filter(
        (detail) => detail.chain === 'bitcoin',
      );

      // if there is no existing wallets, always derive from a child key with BIP84
      if (bitcoinWallets.length === 0) {
        logger.logInfo(
          'createFromMnemonic',
          'No existing bitcoin wallets found. Creating a new one...',
        );
        btcWallet = bip32.fromSeed(seed).derivePath("m/84'/0'/0'/0/0");
      } else {
        // if there is an existing wallet (assuming there's only one)
        const btcAddress = bitcoinWallets[0].address;
        if (!btcAddress) {
          throw errorManager.getServerError(
            'createFromMnemonic',
            'Bitcoin address not found',
          );
        }
        btcWallet = deriveWallets(btcAddress, seed);
      }

      if (!btcWallet) {
        throw errorManager.getServerError(
          'createFromMnemonic',
          'Failed to create wallet from mnemonic',
        );
      }

      const privateKeyWIF = btcWallet.toWIF();
      const publicKey = btcWallet.publicKey.toString('hex');
      const { address } = bitcoin.payments.p2wpkh({
        pubkey: btcWallet.publicKey,
        network:
          chainId === 0 ? bitcoin.networks.bitcoin : bitcoin.networks.testnet,
      });

      if (!address) {
        throw errorManager.getServerError(
          'createFromMnemonic',
          'Bitcoin address not found during creation',
          {
            address,
            chainId,
            publicKey,
          },
        );
      }

      const wallet: AbstractWallet = {
        address,
        network: WalletNetwork.Bitcoin,
        mnemonic: {
          phrase: _mnemonic,
        },
        privateKey: privateKeyWIF,
        publicKey,
        type: WalletNetwork.Bitcoin,
        wallet: btcWallet,
      };
      return {
        data: wallet,
      };
    } catch (error) {
      logger.logError(
        'createFromMnemonic',
        'Error occurred during Bitcoin wallet creation',
        { error, chainId },
      );
      return {
        data: undefined,
        error: error as Error,
      };
    }
  }
}
