import { SegmentTrackEvent } from 'src/types/SegmentTrackEvent';
import { ErrorManager } from 'src/utils/errorManager';
import { v4 as uuidv4 } from 'uuid';
import { UserWalletAnalyticsEvent } from '../types/UserWalletAnalyticsEvent';
import { Logger } from './logger';
import StorageUtils from './storage';

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);

export type EventProperties = any;

export class EventTrackingUtils {
  private static SEGMENT_WRITE_KEY =
    process.env.REACT_APP_SECURE_WALLET_SEGMENT_WRITE_KEY;
  private static SEGMENT_URL =
    process.env.NODE_ENV === 'production'
      ? 'https://buy.moonpay.com/segapi/v1/t'
      : 'https://buy-sandbox.moonpay.com/segapi/v1/t';
  private static MOONPAY_API_URL = process.env.REACT_APP_MOONPAY_API_REST_URL;

  public static async trackEvent(
    event: UserWalletAnalyticsEvent,
    walletAddress: string,
    walletNetwork: string,
    eventProperties: EventProperties = {},
  ) {
    const headers: { 'Content-Type': string; 'X-CSRF-TOKEN'?: string } = {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': StorageUtils.getItem('csrfToken'),
    };

    const body = JSON.stringify({
      payload: {
        eventType: event,
        timestamp: this.generateTimestamp(),
        origin: window.location.origin,
        messageId: this.generateMessageId(),
        walletAddress,
        walletNetwork,
        ...eventProperties,
      },
    });

    try {
      const response = await fetch(
        `${this.MOONPAY_API_URL}/wallets/v1/user/user-wallet-analytics-event`,
        { method: 'POST', headers, credentials: 'include' as const, body },
      );

      if (!response.ok) {
        throw errorManager.getServerError(
          'trackEvent',
          `Failed to send wallet analytics event: ${response.statusText}`,
          {
            response,
            body,
          },
        );
      }
    } catch (e: any) {
      logger.logError(
        'trackEvent',
        'Error occurred whilst sending wallet analytics event',
        {
          message: e.message,
          body,
        },
      );
    }
  }

  // DEPRECATED - use trackEvent instead
  public static trackSegmentEvent(
    event: SegmentTrackEvent,
    properties: EventProperties = {},
  ): void {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.SEGMENT_WRITE_KEY}`,
    };

    const eventData = {
      timestamp: this.generateTimestamp(),
      event,
      properties,
      writeKey: this.SEGMENT_WRITE_KEY, // is this redundant with the bearer token?
      messageId: this.generateMessageId(),
    };

    const body = JSON.stringify(eventData);

    fetch(this.SEGMENT_URL, {
      method: 'POST',
      headers: headers,
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw errorManager.getServerError(
            'trackSegmentEvent',
            `Failed to send Segment event: ${response.statusText}`,
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  private static generateMessageId(): string {
    const uniqueId: string = uuidv4();
    const timestamp: number = Math.floor(Date.now() / 1000);
    return `${uniqueId}-${timestamp}`;
  }

  private static generateTimestamp(): string {
    return new Date().toISOString();
  }
}
