import { WalletNetwork } from '@moonpay/login-common';
import { Result } from 'src/types/Result';

// wallet primitives
import { BIP32Interface } from 'bip32';
import { Wallet as EthersWallet } from 'ethers';

export type Mnemonic = {
  phrase: string;
  path?: string;
  locale?: string;
};

export type BaseWallet = {
  mnemonic: Mnemonic;
  privateKey: string;
  publicKey: string;
  address: string;
  network: WalletNetwork;
};

// Concrete Wallet Types
export type Bitcoin = {
  type: WalletNetwork.Bitcoin;
  wallet: BIP32Interface;
} & BaseWallet;

export type Ethereum = {
  type: WalletNetwork.Ethereum;
  wallet: EthersWallet;
} & BaseWallet;

export type AbstractWallet = Bitcoin | Ethereum;

export function match<T>(
  chain: AbstractWallet,
  handlers: {
    Bitcoin: (btc: Bitcoin) => T | Promise<T>;
    Ethereum: (eth: Ethereum) => T | Promise<T>;
  },
): T | Error {
  // TODO: remove this when refactor is done
  if (!chain.type) {
    switch ((chain as any).network) {
      case WalletNetwork.Bitcoin:
        return handlers.Bitcoin(chain as Bitcoin) as T;
      case WalletNetwork.Ethereum:
        return handlers.Ethereum(chain as Ethereum) as T;
      default:
        return new Error('Invalid chain type');
    }
  }

  // TODO: prefer
  switch (chain.type) {
    case WalletNetwork.Bitcoin:
      return handlers.Bitcoin(chain) as T;
    case WalletNetwork.Ethereum:
      return handlers.Ethereum(chain) as T;
    default:
      return new Error('Invalid chain type');
  }
}

interface BaseCryptoWallet<T> {
  create(chainId: number): Result<T>;
  createFromMnemonic(
    mnemonic: string,
    chainId: number,
  ): Promise<Result<AbstractWallet>>;
}

// TODO: prefer only generic type
//
export interface CryptoWallet<T = AbstractWallet> extends BaseCryptoWallet<T> {}
