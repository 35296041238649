import { WalletNetwork } from '@moonpay/login-common';
import { BigNumber, utils } from 'ethers';
import { TokenInfo } from 'src/messages/walletProxy/methods/sendTransaction/erc20/tokenList';

export function formatTransactionCost(cost: string | number, decimalPlaces = 5) {
  const costAsNumber =
    typeof cost === 'string' ? Number.parseFloat(cost) : cost;

  return costAsNumber.toFixed(decimalPlaces) || '0';
}

export function formatErc20Value(token: TokenInfo, value: BigNumber) {
  return value.div(BigNumber.from(10).pow(token.decimals)).toString();
}

export function formatValueEth(value: BigNumber) {
  return utils.formatEther(value);
}

export function formatValueBtc(value: number) {
  return utils.formatUnits(value, 8);
}

export function formatValue(value: number | BigNumber, network: WalletNetwork) {
  return network === WalletNetwork.Ethereum
    ? formatValueEth(value as BigNumber)
    : formatValueBtc(value as number); 
}

export function formatFee(gas: BigNumber, gasPrice: BigNumber, maxPriorityFeePerGas?: BigNumber) {
  return utils.formatEther((gasPrice.add(maxPriorityFeePerGas ?? 0).mul(gas)));
}

export interface TransactionRequest {
  // from undefined tells the wallet to decide itself
  from?: string;
  // to undefined means contract creation
  to?: string;
  // gas can be excluded to let the wallet create it
  gas?: string;
  value?: string;
  // data can be ommitted for regular transfers
  data?: string;
}
