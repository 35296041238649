import { WalletNetwork } from '@moonpay/login-common';
import { FunctionComponent, useEffect, useState } from 'react';
import {
  MoreActionsPillBody,
  MoreActionsPillBodyProps,
} from 'src/messages/walletProxy/methods/openPill/components/MoreActions';
import PillBody from 'src/messages/walletProxy/methods/openPill/components/PillBody';
import {
  MenuItemHeader,
  PillHeader,
} from 'src/messages/walletProxy/methods/openPill/components/PillHeader';

import { Wallet as EthersWallet } from 'ethers';
import {
  SwitchNetworkPillBody,
  SwitchNetworkPillBodyProps,
} from 'src/messages/walletProxy/methods/openPill/components/SwitchNetwork';
import { getChainIcon } from 'src/messages/walletProxy/methods/switchNetwork/SwitchNetworkPrompt';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';

export type PillProps = {
  userWalletInfo: {
    address: string;
    balance: string;
    chainId: number;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
  };
  initialView?: PillView;
  walletStorage: WalletStorage;
  wallet: EthersWallet;
  network: WalletNetwork;
  origin: string;
};

export enum PillView {
  MAIN,
  SWITCH_NETWORK,
  MORE_ACTIONS,
}

const Pill: FunctionComponent<PillProps> = ({
  userWalletInfo: { address, balance, chainId, nativeCurrency },
  initialView = PillView.MAIN,
  walletStorage,
  wallet,
  origin,
  network,
}) => {
  const [view, setView] = useState<PillView>(initialView);
  const [networkLogo, setNetworkLogo] = useState<
    FunctionComponent<React.SVGProps<SVGSVGElement>>
  >(getChainIcon(network, String(chainId)));

  useEffect(() => {
    setView(PillView.MAIN);
  }, []);

  const changeView = (view: PillView) => {
    setView(view);
  };

  const changeNetworkLogo = (chainId: number) => {
    const icon = getChainIcon(network, String(chainId));
    setNetworkLogo(icon);
  };

  switch (view) {
    case PillView.MAIN:
      return (
        <>
          <PillHeader
            address={address}
            currentNetwork={chainId}
            currentNetworkLogo={networkLogo}
            changeViewCallback={changeView}
          />

          <PillBody balance={balance} nativeCurrency={nativeCurrency} />
        </>
      );
    case PillView.SWITCH_NETWORK:
      const bodyProps: SwitchNetworkPillBodyProps = {
        walletStorage,
        wallet,
        origin,
        network,
        onNetworkChange: changeNetworkLogo,
      };

      return (
        <>
          <MenuItemHeader
            changeViewCallback={changeView}
            title="Change Network"
          />
          <SwitchNetworkPillBody {...bodyProps} />
        </>
      );
    case PillView.MORE_ACTIONS:
      const moreActionsBodyProps: MoreActionsPillBodyProps = {
        walletStorage,
        wallet,
        origin,
        onNetworkChange: changeNetworkLogo,
      };

      return (
        <>
          <MenuItemHeader
            changeViewCallback={changeView}
            title="Change Network"
          />
          <MoreActionsPillBody {...moreActionsBodyProps} />
        </>
      );
    default:
      return null;
  }
};

export default Pill;
