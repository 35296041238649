import styled from 'styled-components';

export const SeedPhraseVerificationContainer = styled.div<{
  isDarkMode?: boolean;
}>`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  border-radius: 0.75rem;
  gap: 0.5rem;
  padding: 1.25rem;

  background-color: ${(props) =>
    props.isDarkMode
      ? 'var(--sys-background-secondary-dark)'
      : 'var(--sys-background-secondary)'};
`;

export const SeedPhraseIndex = styled.div<{ isDarkMode?: boolean }>`
  color: ${(props) =>
    props.isDarkMode
      ? 'var(--label-secondary-dark)'
      : 'var(--label-secondary)'};
`;

export const SeedPhraseInput = styled.input<{ isDarkMode?: boolean }>`
  background-color: ${(props) =>
    props.isDarkMode
      ? 'var(--fill-quaternary-dark)'
      : 'var(--fill-quaternary)'};
  width: 100%;
  padding: 0.25rem;
  padding-left: 1.75rem;
  border-radius: 0.375rem;
  border: 1px solid transparent;
  color: ${(props) =>
    props.isDarkMode ? 'var(--label-primary-dark)' : 'var(--label-primary)'};
  font-weight: 500;
  outline: none;

  &:focus {
    border: 1px solid
      ${(props) =>
        props.isDarkMode
          ? 'var(--accent-primary-dark)'
          : 'var(--accent-primary)'};
  }
`;
