export enum SegmentTrackEvent {
  'signatureRequestApproved' = 'Signature Request Approved',
  'signatureRequestCancelled' = 'Signature Request Cancelled',
  'approveConnectionCompleted' = 'Approve Connection Completed',
  'approveConnectionCancelled' = 'Approve Connection Cancelled',
  'signTypedDataSigned' = 'Sign Typed Data Signed',
  'signTypedDataCancelled' = 'Sign Typed Data Cancelled',
  'genericApproveTransactionSent' = 'Generic Approve Transaction Sent',
  'genericApproveTransactionCompleted' = 'Generic Approve Transaction Completed',
  'genericApproveTransactionCancelled' = 'Generic Approve Transaction Cancelled',
  'sendNFTTransactionSent' = 'Send NFT Transaction Sent',
  'sendNFTTransactionCancelled' = 'Send NFT Transaction Cancelled',
  'approveTokensAllowed' = 'Approve Tokens Allowed',
  'approveTokensCancelled' = 'Approve Tokens Cancelled',
  'approveNFTAllowed' = 'Approve NFT Allowed',
  'approveNFTCancelled' = 'Approve NFT Cancelled',
  'fillOrderConfirmed' = 'Fill Order Confirmed',
  'fillOrderCancelled' = 'Fill Order Cancelled',
  'switchNetworkCompleted' = 'Switch Network Completed',
  'switchNetworkCancelled' = 'Switch Network Cancelled',
  'switchNetworkFailed' = 'Switch Network Failed',
  'switchNetworkUnsupported' = 'Switch Network Unsupported',
}
