import { SecureWalletUxMode } from '@moonpay/login-common';
import CryptoJS from 'crypto-js';
import { ethers } from 'ethers';
import Cookies from 'js-cookie';
import { ErrorManager } from 'src/utils/errorManager';
import StorageUtils from 'src/utils/storage';
import { EthereumChainSpec } from 'src/wallet/storage/ChainsStorage';
import { WalletStorage } from 'src/wallet/storage/WalletStorage';
import { AbstractWallet, Mnemonic } from 'src/wallet/types/Wallet';
import { EncryptionKey } from 'src/wallet/types/apiResponses';
import executeGraphQLQuery from '../../moonpayApi';
import queries from '../../moonpayApi/queries';
import { base58_to_binary } from 'base58-js';
import { createHash } from 'sha256-uint8array';
import { bech32, bech32m } from 'bech32';
import {
  AddressInfo,
  AddressType,
  BitcoinNetwork,
  addressTypes,
} from 'src/messages/walletProxy/utils/validateBitcoinAddress';

const errorManager = new ErrorManager(__filename);
export class WalletHelpers {
  public static parseUxModeParam(readValue: string | null) {
    if (!readValue) {
      return SecureWalletUxMode.NoUx;
    }
    const uxMode = String(readValue) as SecureWalletUxMode;
    const allUxModes = Object.values(SecureWalletUxMode);
    if (!allUxModes.includes(uxMode)) {
      return SecureWalletUxMode.NoUx;
    }
    return uxMode;
  }

  public static parseOriginParam(readValue: string | null) {
    if (!readValue) {
      /*
        Only gets called in getTargetOrigin for origin
        where null/undefined values are explicitly handled
      */
      throw errorManager.getWarnError(
        'parseOriginParam',
        `target must be specified to get origin`,
      );
    }
    return readValue;
  }

  public static parseApiKey(readValue: string | null) {
    if (!readValue) {
      throw errorManager.getServerError(
        'parseApiKey',
        `apiKey must be specified`,
      );
    }
    return readValue;
  }

  public static parseHeadlessParam(readValue: string | null) {
    if (!readValue) {
      return false;
    }
    return readValue === 'true';
  }

  public static parseInitialNetwork(readValue: string | null) {
    if (!readValue) {
      return false;
    }
    return Number(readValue);
  }

  public static parseQueryParams(searchParams: URLSearchParams) {
    const uxModeParam = searchParams.get('uxMode');
    const uxMode = this.parseUxModeParam(uxModeParam);
    const origin = this.parseOriginParam(searchParams.get('target'));
    const apiKey = this.parseApiKey(searchParams.get('apiKey'));
    const isHeadless = this.parseHeadlessParam(searchParams.get('headless'));
    const initialNetworkEth = this.parseInitialNetwork(
      searchParams.get('ethNetwork') || searchParams.get('network'),
    );
    const initialNetworkBtc = this.parseInitialNetwork(
      searchParams.get('btcNetwork'),
    );
    const noModal = searchParams.get('noModal') === 'true';
    const themeId = searchParams.get('themeId');
    const colorScheme = searchParams.get('colorScheme');
    return {
      uxMode,
      origin,
      apiKey,
      isHeadless,
      initialNetworkEth,
      initialNetworkBtc,
      noModal,
      themeId,
      colorScheme,
    };
  }

  public static removeTrailingSlash(url: string): string {
    if (url === null || url === undefined) {
      throw errorManager.getServerError(
        'removeTrailingSlash',
        `url is null or undefined`,
      );
    }

    return url?.replace(/\/$/, '');
  }

  public static isLocalhostUrl(baseUrl: string): boolean {
    const url = new URL(baseUrl);
    return url.hostname === 'localhost';
  }

  public static getCookie(cookieName: string): string | undefined {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (StorageUtils.useServiceWorkerTokenStorage()) {
      return StorageUtils.getItem(cookieName as any);
    }

    return Cookies.get(cookieName);
  }

  public static getCsrfToken(): string | undefined {
    return this.getCookie('csrfToken');
  }

  public static getNetworkProvider(
    chain: EthereumChainSpec,
  ): ethers.providers.JsonRpcProvider {
    // TODO: Only the first rpc url is used here but you can also setup fallbacks
    const rpcUrl = chain.rpcUrls[0];
    if (!rpcUrl) {
      throw errorManager.getServerError(
        'getNetworkProvider',
        `rpc url required to get network provider`,
        { chain },
      );
    }
    return new ethers.providers.JsonRpcProvider({
      url: rpcUrl,
    });
  }

  public static async getDecryptedWallet(
    csrfToken: string,
    walletStorage: WalletStorage,
  ): Promise<AbstractWallet> {
    const { data: encryptionKey } = await executeGraphQLQuery<EncryptionKey>(
      csrfToken,
      queries.walletEncryptionKey,
    );
    if (!encryptionKey) {
      throw errorManager.getServerError(
        'getDecryptedWallet',
        `No encryption key found`,
        {
          walletStorage,
        },
      );
    }
    const encryptedWallet = walletStorage.encryptedWallet.value;
    if (!encryptedWallet) {
      throw errorManager.getServerError(
        'getDecryptedWallet',
        `No wallet found`,
        {
          walletStorage,
        },
      );
    }

    // decrypt wallet
    return this.decryptWallet(encryptedWallet, encryptionKey);
  }

  public static decryptWallet(
    encryptedWallet: string,
    encryptionKey: EncryptionKey,
  ): AbstractWallet {
    const stringifiedWallet = this.decrypt(encryptedWallet, encryptionKey);
    return JSON.parse(stringifiedWallet) as AbstractWallet;
  }

  public static encrypt(
    secret: string,
    keyParams: { key: string; iv: string },
  ): string {
    const encryptedText = CryptoJS.AES.encrypt(secret, keyParams.key, {
      iv: CryptoJS.enc.Utf8.parse(keyParams.iv),
    });

    return encryptedText.toString();
  }

  public static decrypt(
    encryptedSecret: string,
    keyParams: { key: string; iv: string },
  ): string {
    const result = CryptoJS.AES.decrypt(encryptedSecret, keyParams.key, {
      iv: CryptoJS.enc.Utf8.parse(keyParams.iv),
    });

    return result.toString(CryptoJS.enc.Utf8);
  }

  public static encryptMnemonic(
    mnemonic: Mnemonic,
    keyParams: { key: string; iv: string },
  ): string {
    const payloadToEncrypt = JSON.stringify(mnemonic);

    return this.encrypt(payloadToEncrypt, keyParams);
  }

  public static decryptMnemonic(
    encryptedMnemonic: string,
    keyParams: { key: string; iv: string },
  ) {
    const decryptedPayload = this.decrypt(encryptedMnemonic, keyParams);

    return JSON.parse(decryptedPayload) as Mnemonic;
  }

  public static isValidBTCAddress(
    address: string,
    network?: BitcoinNetwork,
  ): boolean {
    try {
      const addressInfo = this.getAddressInfo(address);

      if (network) {
        return network === addressInfo.network;
      }

      return true;
    } catch (error) {
      throw errorManager.getClientError(
        'isValidBTCAddress',
        `Invalid address`,
        { address, network, error },
      );
    }
  }

  private static getAddressInfo(address: string): AddressInfo {
    let decoded: Uint8Array;
    const prefix = address.substring(0, 2).toLowerCase();

    if (prefix === 'bc' || prefix === 'tb') {
      return this.parseBech32(address);
    }

    decoded = this.decodeBase58(address);

    if (decoded.length !== 25) {
      throw errorManager.getClientError(
        'getAddressInfo',
        `base58_to_binary decoded - Invalid length`,
      );
    }

    const version = decoded[0];
    const checksum = decoded.slice(decoded.length - 4);
    const body = decoded.slice(0, decoded.length - 4);

    if (!this.isValidChecksum(body, checksum)) {
      throw errorManager.getClientError(
        'getAddressInfo',
        `base58_to_binary decoded - Invalid checksum`,
      );
    }

    if (!this.isValidVersion(version)) {
      throw errorManager.getClientError(
        'getAddressInfo',
        `base58_to_binary decoded - Invalid version`,
      );
    }

    const addressType = addressTypes[version];

    return {
      ...addressType,
      address,
      bech32: false,
    };
  }

  private static decodeBase58(address: string): Uint8Array {
    try {
      return base58_to_binary(address);
    } catch (error) {
      throw errorManager.getClientError(
        'decodeBase58',
        `base58_to_binary failed`,
      );
    }
  }

  private static isValidChecksum(
    body: Uint8Array,
    checksum: Uint8Array,
  ): boolean {
    const sha256 = (payload: Uint8Array) =>
      createHash().update(payload).digest();
    const expectedChecksum = sha256(sha256(body)).slice(0, 4);
    return checksum.every(
      (value: number, index: number) => value === expectedChecksum[index],
    );
  }

  private static isValidVersion(version: number): boolean {
    const validVersions = Object.keys(addressTypes).map(Number);
    return validVersions.includes(version);
  }

  private static parseBech32(address: string): AddressInfo {
    let decoded;

    try {
      if (
        address.startsWith('bc1p') ||
        address.startsWith('tb1p') ||
        address.startsWith('bcrt1p')
      ) {
        decoded = bech32m.decode(address);
      } else {
        decoded = bech32.decode(address);
      }
    } catch (error) {
      throw errorManager.getClientError('parseBech32', `Invalid address`);
    }

    if (!decoded) {
      throw errorManager.getClientError('parseBech32', `Invalid address`);
    }

    const mapPrefixToNetwork: { [key: string]: BitcoinNetwork } = {
      bc: BitcoinNetwork.mainnet,
      tb: BitcoinNetwork.testnet,
      bcrt: BitcoinNetwork.regtest,
    };

    const network: BitcoinNetwork = mapPrefixToNetwork[decoded.prefix];

    if (network === undefined) {
      throw errorManager.getClientError('parseBech32', `Invalid address`);
    }

    const witnessVersion = decoded.words[0];

    if (witnessVersion < 0 || witnessVersion > 16) {
      throw errorManager.getClientError('parseBech32', `Invalid address`);
    }
    const data = bech32.fromWords(decoded.words.slice(1));

    let type;

    if (data.length === 20) {
      type = AddressType.p2wpkh;
    } else if (witnessVersion === 1) {
      type = AddressType.p2tr;
    } else {
      type = AddressType.p2wsh;
    }

    return {
      bech32: true,
      network,
      address,
      type,
    };
  }
}
