import { Logger } from 'src/utils/logger';
import { ColorSchemeVariant } from 'src/wallet/types/ColorSchemeVariant';
import './SeedPhrasePreview.css';
import {
  SeedPhraseContainer,
  SeedPhraseIndex,
  SeedPhrasePreviewContainer,
} from './SeedPhrasePreview.styles';

const logger = new Logger(__filename);

interface SeedPhrasePreviewProps {
  seedPhrase: string;
}

function SeedPhrasePreview({ seedPhrase }: SeedPhrasePreviewProps) {
  logger.logInfo('SeedPhrasePreview', 'Rendering <SeedPhrasePreview /> ...');

  const { searchParams } = new URL(window.location.href);
  const theme: string = searchParams.get('theme') ?? 'light';

  return (
    <SeedPhrasePreviewContainer
      data-testid="SeedPhrasePreview"
      className="seed-phrase-preview-container"
      isDarkMode={theme === ColorSchemeVariant.Dark}
    >
      {seedPhrase?.split(' ').map((phrase, index) => (
        <SeedPhraseContainer
          key={phrase}
          className="seed-phrase-container"
          isDarkMode={theme === ColorSchemeVariant.Dark}
        >
          <SeedPhraseIndex
            className="seed-phrase-index"
            isDarkMode={theme === ColorSchemeVariant.Dark}
          >
            {index + 1}
          </SeedPhraseIndex>{' '}
          {phrase}
        </SeedPhraseContainer>
      ))}
    </SeedPhrasePreviewContainer>
  );
}

export default SeedPhrasePreview;
