import styled from 'styled-components';

export const SeedPhrasePreviewContainer = styled.div<{
  isDarkMode?: boolean;
}>`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  border-radius: 0.75rem;
  gap: 0.5rem;
  padding: 1.25rem;
  background-color: ${(props) =>
    props.isDarkMode
      ? 'var(--sys-background-primary-dark)'
      : 'var(--sys-background-primary)'};
`;

export const SeedPhraseContainer = styled.div<{ isDarkMode?: boolean }>`
  color: ${(props) =>
    props.isDarkMode ? 'var(--label-primary-dark)' : 'var(--label-primary)'};
`;

export const SeedPhraseIndex = styled.span<{ isDarkMode?: boolean }>`
  color: ${(props) =>
    props.isDarkMode
      ? 'var(--label-secondary-dark)'
      : 'var(--label-secondary)'};
`;
