import { useMemo } from 'react';

type UseRandomSeedPhraseSampleResult = {
  possibleIndexes: number[];
  sampleIndexes: number[];
};

type UseRandomSeedPhraseSample = (
  seedPhrase: string,
  sampleSize: number,
) => UseRandomSeedPhraseSampleResult;

const getPossibleIndexesFromSeedPhrase = (seedPhrase: string): number[] => {
  const seedPhraseArray = seedPhrase.split(' ');

  const possibleIndexes = Array(seedPhraseArray.length)
    .fill(0)
    .map((_, index) => index);

  return possibleIndexes;
};

const getRandomSampleFromSeedPhrase = (
  seedPhrase: string,
  sampleSize: number,
): UseRandomSeedPhraseSampleResult => {
  const possibleIndexes = getPossibleIndexesFromSeedPhrase(seedPhrase);

  const sampleIndexes = [...possibleIndexes]
    .sort(() => Math.random() - 0.5)
    .slice(0, sampleSize);

  return {
    possibleIndexes,
    sampleIndexes,
  };
};

const useRandomSeedPhraseSample: UseRandomSeedPhraseSample = (
  seedPhrase: string,
  sampleSize: number,
) => {
  const memoedSample = useMemo(
    () => getRandomSampleFromSeedPhrase(seedPhrase, sampleSize),
    [seedPhrase, sampleSize],
  );

  return memoedSample;
};

export default useRandomSeedPhraseSample;
