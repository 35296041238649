import { useEffect } from 'react';
import getTargetOrigin from '../getTargetOrigin';

interface UseHandleIncomingMessagesProps {
  messageType: string;
  messageHandler: (payload: any) => void;
  origins?: string[];
}

export const createMessageHandler: CreateMessageEventSignature = ({
  messageType,
  messageHandler,
  origins = [],
}) => {
  const targetOrigin = getTargetOrigin(origins);

  const handleIncomingMessages = (msg: MessageEvent) => {
    if (msg.origin !== targetOrigin) {
      return;
    }

    if (msg.data.type !== messageType) {
      return;
    }
    messageHandler(msg.data);
  };

  return handleIncomingMessages;
};

/**
 * Registers a handler to be invoked when a message is received.
 */
const useHandleIncomingMessages: (
  props: UseHandleIncomingMessagesProps,
) => void = (args) => {
  useEffect(() => {
    const handleIncomingMessages = createMessageHandler(args);

    window.addEventListener('message', handleIncomingMessages);
    return () => window.removeEventListener('message', handleIncomingMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

type CreateMessageEventSignature = (
  props: UseHandleIncomingMessagesProps,
) => (msg: MessageEvent) => void;

export default useHandleIncomingMessages;
