import { Label, Text } from '@moonpay-widget/ui-kit';
import BigNumber from 'bignumber.js';
import { FC } from 'react';

export type NftPurchaseProps = {
  nfts: {
    name: string;
    price: number;
  }[];
  networkFee?: string;
  currencyCode: string;
};

const NftPurchase: FC<NftPurchaseProps> = ({
  nfts,
  networkFee,
  currencyCode,
}) => {
  let totalAmount = 0;
  nfts.forEach((nft) => {
    totalAmount = totalAmount + nft.price;
  });

  return (
    <>
      <div className="flex mt-8 items-center justify-between">
        <Label>Summary</Label>
      </div>
      <div className="px-4 mb-2 bg-system-background-secondary rounded-xl overflow-auto">
        {nfts.map((nft) => {
          return (
            <Text
              as="div"
              variant="footnote"
              className="flex my-4"
              key={nft.name}
            >
              <div className="float-left w-1/2">{nft.name} price</div>
              <div className="float-right w-1/2 text-right">{`${nft.price} ${currencyCode}`}</div>
            </Text>
          );
        })}
        <Text
          as="div"
          variant="footnote"
          className="flex my-4 border-b pb-4 border-divider-nonOpaque"
        >
          <div className="float-left w-1/2">Blockchain fee</div>
          <div className="float-right w-1/2 text-right ">{` ${
            networkFee || '0'
          } ${currencyCode}`}</div>
        </Text>
        <Text variant="subhead" as="div" className="flex my-4">
          <div className="float-left w-1/2">
            {`Buying `}
            <b>{`${nfts.length} ${nfts.length > 1 ? 'NFTs' : 'NFT'}`}</b>
            {` for`}
          </div>
          <div className="float-right w-1/2 text-right font-bold">
            {`${new BigNumber(totalAmount).plus(
              networkFee || 0,
            )} ${currencyCode}`}
          </div>
        </Text>
      </div>
    </>
  );
};

export default NftPurchase;
