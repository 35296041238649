import { WalletNetwork } from "@moonpay/login-common";
import { ActiveChainsData } from "src/wallet/storage/ActiveChainStorage";

export const DEFAULT_ACTIVE_CHAINS: ActiveChainsData = {
    [WalletNetwork.Ethereum]: 5, // goerli
    [WalletNetwork.Bitcoin]: 0, // mainnet
}



