import { FC } from 'react';
import useHandleIncomingMessages from 'src/messages/hooks/useHandleIncomingMessages';
import { BackupWalletComponentProps } from '../BackupWalletFlow/BackupWalletComponent.props';
import SeedPhraseVerification from '../SeedPhraseVerification';

const BackupWalletManually: FC<BackupWalletComponentProps> = ({
  wallet,
  onWalletBackedUp,
}) => {
  useHandleIncomingMessages({
    messageType: 'backup-wallet',
    messageHandler: onWalletBackedUp,
  });

  return <SeedPhraseVerification seedPhrase={wallet.mnemonic.phrase} />;
};

export default BackupWalletManually;
