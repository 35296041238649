import { WalletMessageType } from '@moonpay/login-common';
import { Logger } from 'src/utils/logger';
import { createMessageHandler } from '../hooks/useHandleIncomingMessages';
import { HandleWalletMessageProxyMessageParams } from './registerWalletMessageProxyHandler';

const logger = new Logger(__filename);

const addWalletMessageProxyEventListener = (
  handler: (payload: HandleWalletMessageProxyMessageParams) => void,
  origins: string[],
) => {
  const walletMessageProxyMessageHandler = createMessageHandler({
    messageType: WalletMessageType.MESSAGE_PROXY,
    messageHandler: handler,
    origins,
  });

  console.debug('Registering wallet message proxy event listener');
  logger.logDebug(
    'Registering wallet message proxy event listener',
    'addWalletMessageProxyEventListener',
  );
  window.addEventListener('message', walletMessageProxyMessageHandler);
};

export default addWalletMessageProxyEventListener;
