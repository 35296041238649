import { datadogLogs } from "@datadog/browser-logs";

export class Logger {
  private filename: string;

  constructor(filename: string) {
    this.filename = Logger.getFilename(filename);
  }

  private static getFilename(filename: string): string {
    let split;

    if (filename.includes('/')) {
      // Mac & Linux
      split = filename.split('/');
    } else if (filename.includes('\\')) {
      // Windows
      split = filename.split('\\');
    } else {
      return filename;
    }

    return split[split.length - 1];
  }

  private static shouldLog(): boolean {
    return process.env.NODE_ENV === 'production';
  }

  public logInfo(functionName: string, message: any, data?: unknown): void {
    if (Logger.shouldLog()) {
      datadogLogs.logger.info(message, {
        filename: this.filename,
        functionName,
        ...(data ? { data } : undefined)
      })
    }
  }

  public logDebug(functionName: string, message: any, data?: unknown): void {
    if (Logger.shouldLog()) {
      datadogLogs.logger.debug(message, {
        filename: this.filename,
        functionName,
        ...(data ? { data } : undefined)
      })
    }
  }

  public logError(functionName: string, message: any, data?: unknown): void {
    if (Logger.shouldLog()) {
      datadogLogs.logger.error(message, {
        filename: this.filename,
        functionName,
        ...(data ? { data } : undefined)
      })
    }
  }

  public logWarn(functionName: string, message: any, data?: unknown): void {
    if (Logger.shouldLog()) {
      datadogLogs.logger.warn(message, {
        filename: this.filename,
        functionName,
        ...(data ? { data } : undefined)
      })
    }
  }
}
