import { ErrorManager } from 'src/utils/errorManager';
import { WalletHelpers } from 'src/wallet/helpers/WalletHelpers';

const errorManager = new ErrorManager(__filename);

const getTargetOrigin = (origins?: string[]) => {
  if (!origins || !origins.length) {
    throw errorManager.getServerError(
      'getTargetOrigin',
      `No allowable origins provided`,
    );
  }

  const { searchParams } = new URL(window.location.href);
  const { origin } = WalletHelpers.parseQueryParams(searchParams);

  let originToCheck;

  if (!origin) {
    throw errorManager.getServerError(
      'getTargetOrigin',
      `origin is null or undefined`,
    );
  } else {
    originToCheck = origin.replace(/\/$/, '');
  }

  if (new URL(originToCheck).hostname === 'localhost') {
    return originToCheck;
  }

  if (
    originToCheck.startsWith('https://') &&
    originToCheck.endsWith('.moonpay-dev.com')
  ) {
    return originToCheck;
  }

  if (
    originToCheck.startsWith('https://') &&
    originToCheck.endsWith('.moonpay.com')
  ) {
    return originToCheck;
  }

  if (!origins.includes(originToCheck)) {
    throw errorManager.getServerError('getTargetOrigin', `Origin not allowed`);
  }

  return originToCheck;
};

export default getTargetOrigin;
