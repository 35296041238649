import { ErrorManager } from 'src/utils/errorManager';
import executeGraphQLQuery from '.';
import { sendMessageToParent } from '../messages/Message';
import { Logger } from '../utils/logger';
import { AbstractWallet } from '../wallet/types/Wallet';
import queries from './queries';

const logger = new Logger(__filename);
const errorManager = new ErrorManager(__filename);
export interface WalletBackupSuccessParams {
  csrfToken: string;
  provider: 'gdrive' | 'manual';
  backupMethod: 'Google' | 'Manual';
}

export type FinalizeFlowParams = WalletBackupSuccessParams & {
  wallet: AbstractWallet;
};

/**
 * When the backup of the wallet has been completed (either cloud or manual)
 * we need to fire two calls through the Moonpay API:
 * - `createWalletAddress`, that has alters both the moonpay API BE and the consumer API BE
 *   On the Moonpay API BE, we create a new record in the `wallet` table (if not existing, which should always be the case) and then
 *   we associate the new wallet to the customer (new record in the `customer_wallet` table)
 *   On the Consumer API BE, we add a new record to the `wallet_address` table.
 * - `createWalletBackup`, that alters only the Consumer API BE by adding a new record to
 *   the `wallet_backup` table, which the app then will use to restore the user device.
 */
async function finalizeDhwFlow({
  csrfToken,
  provider,
  backupMethod,
  wallet,
}: FinalizeFlowParams): Promise<void> {
  // register wallet address
  const walletNetwork = wallet.network;
  const walletAddress = wallet.address;
  logger.logInfo(
    'finalizeDhwFlow',
    `Adding wallet address (${walletNetwork}) ${walletAddress}...`,
    { walletNetwork },
  );
  const { error: createWalletAddressError } = await executeGraphQLQuery(
    csrfToken,
    {
      query: () =>
        queries.createWalletAddress.query([walletAddress, walletNetwork]),
      getter: queries.createWalletAddress.getter,
    },
  );
  if (createWalletAddressError) {
    const errorMessage = `Error in creating the wallet address: ${JSON.stringify(
      createWalletAddressError,
    )}`;
    throw errorManager.getServerError(
      'finalizeDhwFlow',
      `Error in creating wallet address ${errorMessage}`,
    );
  }

  logger.logInfo(
    'finalizeDhwFlow',
    `...done adding wallet address ${walletAddress}`,
    { walletNetwork },
  );

  logger.logInfo(
    'finalizeDhwFlow',
    `Creating wallet backup for address ${walletAddress}...`,
    { walletNetwork },
  );
  const { error: backupWalletAddressError } = await executeGraphQLQuery(
    csrfToken,
    {
      query: () =>
        queries.createWalletBackup.query([
          walletAddress,
          walletNetwork,
          provider,
          backupMethod,
        ]),
      getter: () => queries.createWalletBackup.getter,
    },
  );
  if (backupWalletAddressError) {
    const errorMessage = `Error in backing up the wallet address: ${JSON.stringify(
      backupWalletAddressError,
    )}`;
    throw errorManager.getServerError(
      'finalizeDhwFlow',
      `Error in creating wallet address ${errorMessage}`,
    );
  }

  logger.logInfo(
    'finalizeDhwFlow',
    `...done creating wallet backup for address ${walletAddress}`,
    { walletNetwork },
  );

  // send success message to widget
  sendMessageToParent('backup-wallet-success', []);
}

export default finalizeDhwFlow;
