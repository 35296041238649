import { BigNumber, ethers, Wallet } from 'ethers';
import { TransactionRequest } from 'src/messages/walletProxy/methods/sendTransaction/utils';
import hmErc721 from './HyperMintERC721A_2_2_0.json';

export enum HmErc721TransactionType {
  BUY = 'buy',
}

export interface HmErc721Action {
  type: HmErc721TransactionType;
}

export interface BuyToken extends HmErc721Action {
  amount: BigNumber;
  type: HmErc721TransactionType.BUY;
}

export type HmErc721Actions = BuyToken;

export async function getHmErc721Action(
  wallet: Wallet,
  tx: TransactionRequest,
): Promise<HmErc721Actions | undefined> {
  const { to, data } = tx;
  if (!to || !data) {
    return;
  }

  const tokenContract = new ethers.Contract(
    tx.to!.toLowerCase(),
    hmErc721,
    wallet.provider,
  );
  try {
    const parsed = tokenContract.interface.parseTransaction({ data });
    if (parsed.name === 'buy') {
      return {
        amount: parsed.args._amount as BigNumber,
        type: HmErc721TransactionType.BUY,
      };
    }
    if (parsed.name === 'buyAuthorised') {
      return {
        amount: parsed.args._amount as BigNumber,
        // TODO: improve buyAuthorised with more detailed props
        // totalPrice: parsed.args._totalPrice as BigNumber,
        type: HmErc721TransactionType.BUY,
      };
    }
  } catch (error) {
    // swallow error
  }
}
