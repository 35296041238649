import { WalletNetwork } from '@moonpay/login-common';
import { ethers } from 'ethers';
import { Result } from 'src/types/Result';
import { Logger } from 'src/utils/logger';
import { AbstractWallet, CryptoWallet } from 'src/wallet/types/Wallet';

const logger = new Logger(__filename);

export const DEFAULT_PATH = "m/44'/60'/0'/0/0";
export const DEFAULT_LOCALE = 'en';

export class EthereumWallet implements CryptoWallet {
  // eslint-disable-next-line class-methods-use-this
  create(chainId: number): Result<AbstractWallet> {
    try {
      const { address, mnemonic, privateKey, publicKey } =
        ethers.Wallet.createRandom({
          path: DEFAULT_PATH,
          locale: DEFAULT_LOCALE,
        });

      const wallet: AbstractWallet = {
        address,
        network: WalletNetwork.Ethereum,
        mnemonic,
        privateKey,
        publicKey,
        //
        type: WalletNetwork.Ethereum,
        wallet: new ethers.Wallet(privateKey),
      };
      return {
        data: wallet,
      };
    } catch (error) {
      logger.logError(
        'create',
        'Error occurred during Ethereum wallet creation',
        { error, chainId },
      );
      return {
        data: undefined,
        error: error as Error,
      };
    }
  }

  async createFromMnemonic(
    _mnemonic: string,
    chainId: number,
  ): Promise<Result<AbstractWallet>> {
    try {
      const { address, mnemonic, privateKey, publicKey } =
        ethers.Wallet.fromMnemonic(_mnemonic, DEFAULT_PATH);

      const wallet: AbstractWallet = {
        address,
        mnemonic,
        privateKey,
        publicKey,
        network: WalletNetwork.Ethereum,
        //
        type: WalletNetwork.Ethereum,
        wallet: new ethers.Wallet(privateKey),
      };
      return {
        data: wallet,
      };
    } catch (error) {
      logger.logError(
        'createFromMnemonic',
        'Error occurred during Ethereum wallet creation',
        { error, chainId },
      );
      return {
        data: undefined,
        error: error as Error,
      };
    }
  }
}
