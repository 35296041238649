type CryptoDetailsProps = {
  balancesWithLogo: any[];
};

export const CryptoDetails = ({ balancesWithLogo }: CryptoDetailsProps) => {
  return (
    <div className="flex flex-col mb-2 space-y-1">
      {balancesWithLogo.map((balance: any, index: number) => (
        <div
          key={index}
          className="flex flex-col items-start py-1 cursor-pointer"
        >
          <div className="flex justify-start w-full">
            {/* Token Icon */}
            <img
              src={balance.token?.logoURI}
              className="w-10 h-10 mt-2 mr-2 rounded-full"
              style={{ backgroundColor: '#C38AFF33' }}
              alt={balance.symbol}
            />

            {/* Currency Row */}
            <div
              className="flex flex-col"
              style={{ width: 'calc(100% - 3rem)' }}
            >
              {/* Top Row */}
              <div className="flex justify-between w-full font-bold">
                <div>{balance.network}</div>
                <div>{balance.fiatAmount}</div>
              </div>

              {/* Bottom Row */}
              <div
                className="flex justify-between w-full"
                style={{
                  color: '#999',
                  fontWeight: 300,
                  fontSize: 13,
                  marginTop: 4,
                }}
              >
                <div>{balance.symbol}</div>
                <div>
                  {balance.balance} {balance.symbol}
                </div>
              </div>
            </div>
            {/* End Currency Row */}
          </div>
        </div>
      ))}
    </div>
  );
};
