import { QueryInfo } from './queries';
import { Logger } from "../utils/logger";

const logger = new Logger(__filename);

const MOONPAY_API_GRAPHQL_URL = process.env.REACT_APP_MOONPAY_API_GRAPHQL_URL as string;

const prepareBaseRequest = ({ csrfToken }: { csrfToken: string }) =>
  ({
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    },
    method: 'POST',
    credentials: 'include',
  } as Pick<RequestInit, 'method' | 'headers' | 'credentials'>);

const stringifyGraphQLQuery = (query: string) =>
  JSON.stringify({
    query,
  });

const executeGraphQLQuery = async <TData>(
  csrfToken: string,
  queryInfo: QueryInfo<[]>,
): Promise<{ data: TData; error: any }> => {
  const request = {
    ...prepareBaseRequest({ csrfToken }),
    body: stringifyGraphQLQuery(queryInfo.query([])),
  };

  const response = await fetch(MOONPAY_API_GRAPHQL_URL, request);
  const responseJson = await response.json();

  const error = responseJson?.errors?.[0]?.message;
  const data = !error ? queryInfo.getter(responseJson.data as TData) : null;

  if (error) {
    logger.logError('executeGraphQLQuery',
    'A GraphQL Error has occurred whilst executing a request', {error, queryInfo, request, responseJson});
    console.error(`[secure-wallet] graphQL Error - ${JSON.stringify(error)}`);
  }

  return {
    data,
    error,
  };
};

export default executeGraphQLQuery;
