import { useEffect, useState } from 'react';
import { getTokenBySymbol } from 'src/messages/walletProxy/methods/sendTransaction/erc20';
import { Bottom } from './Bottom';
import { Hero } from './Hero';

type PillBodyProps = {
  balance: string;
  nativeCurrency: any;
};

const PillBody = ({ balance, nativeCurrency }: PillBodyProps) => {
  let [balancesWithLogo, setBalancesWithLogo] = useState<any>([]);

  let balances = [
    {
      balance: '1.23',
      fiatAmount: '$1,827.09',
      network: 'Ethereum',
      symbol: 'ETH',
      address: '0x1234',
    },
    // polygon
    {
      balance: '1.23',
      fiatAmount: '$1,827.09',
      network: 'Polygon',
      symbol: 'MATIC',
      address: '0x1234',
    },
    // USDC
    {
      balance: '1.23',
      fiatAmount: '$1,827.09',
      network: 'USD Coin',
      symbol: 'USDC',
      address: '0x1234',
    },
    {
      balance: '1.23',
      fiatAmount: '$1,827.09',
      network: '1 Inch',
      symbol: '1INCH',
      address: '0x1234',
    },
    // mana
    {
      balance: '1.23',
      fiatAmount: '$1,827.09',
      network: 'Decentraland',
      symbol: 'MANA',
      address: '0x1234',
    },
    // dai
    {
      balance: '1.23',
      fiatAmount: '$1,827.09',
      network: 'Dai',
      symbol: 'DAI',
      address: '0x1234',
    },
  ];

  // get token logos for each balance
  useEffect(() => {
    (async () => {
      let balancesWithLogo = balances.map(async (balance: any) => {
        let token = await getTokenBySymbol(balance.symbol);
        return {
          ...balance,
          token,
        };
      });

      balancesWithLogo = await Promise.all(balancesWithLogo);
      setBalancesWithLogo(balancesWithLogo);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col justify-between mt-4 px-4 mx-4">
      <Hero balance={balance} nativeCurrency={nativeCurrency} />
      <Bottom balancesWithLogo={balancesWithLogo} />
    </div>
  );
};

export default PillBody;
